// Customizable Area Start
import React from "react";
import { comment, diamond, dont, down, more, question, right, roundplus, shakehand, sigma, topRight } from "./assets";

import JobListingController, {
  configJSON,
  Job,
  JobListDataType
} from "./JobListingController";
import { Box, Button, CircularProgress, Grid, Modal, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CustomBottonComponent from "../../../components/src/CustomBottonComponent.web";
import CloseIcon from '@material-ui/icons/Close';
import CustomChipComponent from "../../../components/src/CustomChipComponent.web";
import PopoverModal from "../../../components/src/PopoverModal.web";
import JobDetailsModal from "./components/JobDetailsModal";
import ConfirmDialog from "../../../components/src/ConfirmDialog.web";
import CreateJobModal from "./components/CreateJobModal";
import { ToastContainer } from "react-toastify";
import JobDetailListing from "./JobDetailsListing.web";
import { TruncateWithTooltip } from "../../../../packages/components/src/Utils.web";
import BootstrapTooltip from "../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";

class JobListing extends JobListingController {

  render() {

    return (
      <>
        {
          !this.state.openJobDetailListing ? <div style={webStyle.container1} id="jobListingPageMain">
            <style>{configJSON.globalCSS}</style>
            <Grid container style={webStyle.container}>
              <Grid item container sm={12} style={{ ...webStyle.header, position: "relative" }}>
                <img src={topRight} alt="icons" style={{ ...webStyle.topRight, position: "absolute" }} />
                <Grid item sm={2}>
                  <Typography component="span" style={webStyle.headertitle}>Jobs</Typography>
                </Grid>
                <Grid>
                  <CustomBottonComponent
                  data-test-id ="post-job"
                    title={<div
                      style={webStyle.buttonTitle}><img src={roundplus} alt="icon" />&nbsp;&nbsp;
                      <Typography style={webStyle.buttonTitleText}>Post a Job</Typography></div>}
                    type="normal"
                    onClick={
                      this.subscriptionDetails
                    }
                  />
                </Grid>
              </Grid>
              <Grid item container sm={12} className="cardContainer" style={webStyle.cardContainer} id="rootContainerScroller">
                {
                  this.state.allCreatedJobs.map((item: Job, index: number) => {
                    return <Grid item container key={item.id} style={webStyle.card} className="list-item-569" onClick={() => this.handleJobDetailListing(item)}>
                      <Grid item container sm={12} style={webStyle.cardBody}>
                        <Grid item sm={12} style={{ ...webStyle.flexBetweenCenter, flexWrap: "wrap" }}>
                          <span style={item.attributes.status !== "close" ? webStyle.cardTitle: webStyle.cardTitleDisabled}>
                            <BootstrapTooltip title={this.getString(item.attributes.job_title, 30)}>
                              <span>{TruncateWithTooltip(item.attributes.job_title, 30)}</span>
                            </BootstrapTooltip>
                          </span>
                          <PopoverModal onView={this.handleViewClick} handleDelete={this.handleConfirm} job={item} handleEditClick={this.handleEditClick} handleJobStatusClick={this.handleChangeJobStatus}>
                            <img alt="icon" src={more} />
                          </PopoverModal>
                        </Grid>
                        <Grid item sm={12} style={{ ...webStyle.flexBetweenCenter, flexWrap: "wrap" }}>
                          <div style={{ ...webStyle.flexBetweenCenter, flexWrap: "wrap" }}>
                            <span style={item.attributes.status !== "close" ? webStyle.positionTitle : webStyle.positionTitleDisabled}>
                              <BootstrapTooltip title={this.getString(item.attributes.department, 30)}>
                              <span>{TruncateWithTooltip(item.attributes.department, 30)}</span>
                            </BootstrapTooltip>
                            </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* <CustomChipComponent title="Applied +" type="applied" showDeleicon={false} /> */}
                          </div>
                          <div style={{ ...webStyle.flexBetweenCenter, flexWrap: "wrap" }}>
                            <span style={this.getRightOne(item.attributes.status !== "close", webStyle.statusTitle, webStyle.statusTitleDisabled) as Object}>Status: </span>&nbsp;&nbsp;&nbsp;
                            {this.getRightOne(item.attributes.status === "close", <CustomChipComponent title="Closed" type={item.attributes.status} showDeleicon={false} />, <CustomChipComponent title={item.attributes.status.charAt(0).toUpperCase() + item.attributes.status.slice(1)} type={item.attributes.status} showDeleicon={false} />)}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} style={item.attributes.status !== "close" ? webStyle.cardFooter : webStyle.cardFooterDisabled}>
                        <div style={{ ...webStyle.flexBetweenCenter, flexWrap: "wrap" }}>
                          <span style={webStyle.footerTitle}>{item.attributes.candidatesCount} <span style={webStyle.footerTitle0}>Candidates</span></span>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <BootstrapTooltip title="All" arrow={false}>
                            <span style={webStyle.countTitle}>
                              <img src={sigma} alt="icon" />
                              &nbsp;{item.attributes.candidatesCount}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Invited" arrow={false}>
                            <span style={webStyle.countTitle}>
                              <img src={down} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count.invited}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Applied+" arrow={false}>
                              <span style={webStyle.countTitle}>
                              <img src={diamond} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count["applied+"]}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Applied" arrow={false}>
                              <span style={webStyle.countTitle}>
                              <img src={shakehand} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count.applied}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Rejected" arrow={false}>
                              <span style={webStyle.countTitle}>
                              <img src={dont} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count.rejected}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Reviewed" arrow={false}>
                              <span style={webStyle.countTitle}>
                              <img src={question} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count.review}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Messaged" arrow={false}>
                              <span style={webStyle.countTitle}>
                              <img src={comment} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count.messaged || 0}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <BootstrapTooltip title="Interviewing" arrow={false}>
                              <span style={webStyle.countTitle}>
                              <img src={right} alt="icon" />
                              &nbsp;{item.attributes.job_applications_count.interviewing}
                            </span>
                            </BootstrapTooltip>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  })
                }
              </Grid>
              <Grid container style={{ display: "flex", width: "100%", justifyContent: "center" }} id="loader-container">
                {this.state.isInfiniteLoading && <CircularProgress style={{ color: '#1F497D' }} />}
              </Grid>
            </Grid>
          </div>
            : <JobDetailListing countries={this.state.countries} cities={this.state.cities}  setRefreshPage={this.setRefreshPage}  handleCountryChange={this.handleCountryChange} jobDetailsFromProps={this.state.selectedJobForDetails} refreshPage={this.state.refreshPage} allCreatedJobs={this.state.allCreatedJobs} handleViewClick={this.handleViewClick} handleDelete={this.handleConfirm} handleEditClick={this.handleEditClick} navigation={this.props.navigation} jobId={this.state.selectedJobForDetails.id} handleJobDetailListingClose={this.handleJobDetailListingClose} />
        }
        <CreateJobModal
          isDisabled={this.isDisabled}
          open={this.state.openAddJobModal}
          handleCountryChange={this.handleCountryChange}
          onClose={this.handleOpenAddJobModal}
          state={this.state} setStep={this.setStep}
          addNewQuestion={this.addNewQuestion}
          addOption={this.addOption}
          handleQuestionChange={this.handleQuestionChange}
          handleOptionChange={this.handleOptionChange}
          handleRequiredChange={this.handleRequiredChange}
          handleRemoveQuestion={this.handleRemoveQuestion}
          handleTypeChange={this.handleTypeChange}
          handleRemoveOption={this.handleRemoveOption}
          focuseId={this.state.focuseId}
          setFocusId={this.setFocusId}
          createJob={this.createJob}
          companyListHandler={this.companyListHandler}
          handleFormOneSubmit={this.handleFormOneSubmit}
          handleFormTwoSubmit={this.handleFormTwoSubmit}
          handleFormThreeSubmit={this.handleFormThreeSubmit}
        />
        <ToastContainer />
        <JobDetailsModal onClose={this.handleModal} open={this.state.openModal} state={this.state} handleEditClick={this.handleEditClick}/>
        <ConfirmDialog open={this.state.openConfirm} handleClose={this.handleConfirm} hadndleDelete={this.handleDeleteJob} questionOne="Are you sure you want to delete this" questionTwo="application?" />
        <Modal
                  open={this.state.isModalOpen}
                  onClose={this.handleModalClose}
                  style={{ width: '546px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '33%',  }}
                >
                  <Grid container>
                    
                  <StyledModalStyle>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <Box ></Box>
                                          <CloseIcon data-test-id="close-forgot-password-modal" style={{marginTop: '5px', color:'rgba(51, 65, 85, 1)', height:'24px', width:'24px'}} onClick={this.handleModalClose} />
                                        </Box>

                                        <StyledTitle>
                                        Subscribe or upgrade now for exclusive
                                        <StyledTitle>
                                        benefits!                                      
                                        </StyledTitle>
                                           </StyledTitle>
                                        <GreyFont>
                                        Seeking top talent? You've hit the job post limit! Don't miss out on connecting with the best candidates. Subscribe now or upgrade your plan to unlock more job postings and supercharge your recruitment efforts!                                        </GreyFont>
                                        <Box sx={{ width: '100%' }}>



                 
                                          <StyledButtonContainer>
                                          <StyledBackButton data-test-id="modal-close" onClick={this.handleModalClose} variant="contained" color="primary">Cancel</StyledBackButton>

                                          <StyledDarkButton data-test-id="navigate"  onClick={this.handleShowPlans} type="button" >Subscribe now</StyledDarkButton>
                                          </StyledButtonContainer> 
                                        </Box>
                                      </StyledModalStyle>
                  </Grid>
                </Modal>
   
      </>
    );
  }
}

export default JobListing;
const StyledModalStyle = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  padding: '25px 35px',
  borderRadius: '8px',
  color: "#1F497D",
  minWidth: '546px',
}));
const StyledTitle = styled(Grid)(({ theme }) => ({
fontFamily: 'Arial',
fontSize: '24px',
fontWeight: 700,
lineHeight: '32px',
color:'rgba(23, 54, 93, 1)'
}));
const GreyFont = styled(Grid)(({ theme }) => ({
fontFamily: 'Arial',
fontSize: '16px',
fontWeight: 400,
color:'rgba(156, 156, 156, 1)',
marginTop:'25px',
marginBottom:'25px'

  }));

  const StyledButtonContainer = styled(Box)(({ theme }) => ({
    fontFamily: 'Arial',
    fontSize: '16px',
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: '10px',
  }));
  const StyledDarkButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Arial',
    height:'56px',
    fontSize: '16px',
    padding: "10px 16px",
    background: "rgba(31, 73, 125, 1)",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "bold",
    alignSelf: "flex-end",
    textTransform: "none",
    '&:hover': {
      background: "rgba(31, 73, 125, 1)",
    },
  }));
  
  const StyledBackButton = styled(Button)(({ theme }) => ({
    boxShadow:'none',
    height:'56px',
    fontFamily: 'Arial',
    fontSize: '16px',
    padding: "10px 18px",
    background: "rgba(244, 244, 244, 1)",
    borderRadius: "8px",
    color: "rgba(31, 73, 125, 1)",
    fontWeight: "bold",
    alignSelf: "flex-end",
    textTransform: "none",
    '&:hover': {
      background: "rgba(244, 244, 244, 1)",
    },
  }));
const webStyle = {
  container: {
    margin: "0px",
    width: "100%",
    background: "#F3F4F8",
    padding: "15px 30px",
  },
  container1: {
    margin: "0px",
    width: "100%",
    background: "#F3F4F8",
    padding: "15px 30px",
    maxWidth: "1280px"
  },
  header: {
    diaplay: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 28px",
    background: "#FFFFFF",
    borderRadius: "16px",
    border: "1px solid #F4F4F4",
    position: "relative"
  },
  topRight: {
    right: 0,
    top: 0,
  },
  headertitle: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "24px",
    color: "#17365D"
  },
  buttonTitle: {
    display: "flex",
    alignItem: "center"
  },
  buttonTitleText: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#FFFFFF"
  },
  cardContainer: {
    padding: "0px 130px"
  },
  card: {
    background: "#FFFFFF",
    borderRadius: "16px",
    margin: "10px 0px",
    border: "1px solid #F4F4F4",
    cursor: "pointer"
  },
  cardBody: {
    padding: "17px",
  },
  cardFooter: {
    padding: "10px 17px",
    background: "#1F497D",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    width: "100%",
  },
  cardFooterDisabled: {
    padding: "10px 17px",
    background: "#9C9C9C",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    width: "100%",
  },
  flexBetweenCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#17365D",
    textDecoration: "underline",
    textDecorationColor: "#17365D",
    textDecorationThickness: "2px"
  },
  cardTitleDisabled: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#9C9C9C",
    textDecoration: "underline",
    textDecorationColor: "#9C9C9C"
  },
  positionTitle: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#1F497D",
  },
  positionTitleDisabled: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#9C9C9C",
  },
  statusTitle: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#17365D",
  },
  statusTitleDisabled: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#9C9C9C",
  },
  footerTitle: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "12px",
    color: "#EEECE1",
  },
  footerTitle0: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#EEECE1",
  },
  countTitle: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#EEECE1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};
// Customizable Area End
