import LandingPageTalentPoolController, {
    Props, Root,
    // Customizable Area Start
    // Customizable Area End
} from "./LandingPageTalentPoolController";
// Customizable Area Start
import { Avatar, Box, Grid, InputAdornment, TextField, Tooltip, Typography, styled } from "@material-ui/core";
import { filter, openMenuicon, EndSearchIcon, SearchIcon, save, scrollDown, saveBlue, imgViewAll } from "./assets";
import React from "react";
import { Helmet } from 'react-helmet';
import { ToastContainer } from "react-toastify";
import Filteroptions from "../../../../packages/blocks/filteritems/src/Filteroptions.web";
import { META_TAGS } from '../../../../packages/components/src/Utils.web';
// Customizable Area End

class LandingPageTalentPool extends LandingPageTalentPoolController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Helmet>
                    <meta
                        name="description"
                        content={`
                        Breaking into Buy-side: Connect and Advance Your Career 
                        Breaking into Sell-side: Connect and Explore Opportunities                         
                        Recruit Talent for Buy-side: Join Our Network 
                        Recruit Talent for Sell-side: Join Our Network                         
          `}
                    />
                    <meta
                        name="keywords"
                        content={META_TAGS}
                    />

                    <meta property="og:title" content="Join Our Platform for Private Equity Professionals" />
                    <meta
                        property="og:description"
                        content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
                    />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:title" content="Join Our Platform for Private Equity Professionals" />
                    <meta
                        name="twitter:description"
                        content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
                    />
                </Helmet>
                <Box sx={{
                    bgcolor: 'white',
                    color: '#17365D',
                    borderRadius: '20px',
                    padding: '15px',
                    position: 'relative',
                }} 
                style={{ overflowY: 'auto', minHeight: this.state.filterHeight }}
                >
                    <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                        <img src={imgViewAll} style={{ width: '13px', height: '13px', marginBottom: '5px', cursor: "pointer" }} />
                    </Box>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <Typography style={webStyle.jobTitle}>Talent Pool</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ ...webStyle.viewAllText, cursor: "pointer" }} onClick={this.handleTalentPoolNavigation} data-test-id="viewAll">View All</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="space-between" style={{ display: "flex", alignItems: "center", marginTop: '14px' }}>
                        <Grid item md={11}>
                            <RootAutoComplete
                                data-test-id="searchField"
                                name="find job list"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="icon" src={SearchIcon} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img alt="icon" src={EndSearchIcon} />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Search"
                                variant="outlined"
                                onChange={this.handleSetSearchField}
                                value={this.state.searchField}
                            />
                        </Grid>
                        <Grid item md={1}>
                            <div>
                                <img src={filter} onClick={this.handleToggle} data-test-id="filterIcon" style={{ marginLeft: '-8px', cursor: 'pointer' }} />
                            </div>
                            <Filteroptions
                                data-test-id="filterOptions"
                                country={(val: string) => { this.setCountryvalue(val) }}
                                city={(val: string) => { this.setCityValue(val) }}
                                navigation={undefined}
                                isRecruiterPage
                                handleFilter={this.handleFilter}
                                id={""}
                                openFilter={(val: boolean) => { this.setOpenFilter(val) }}
                                date={(val: string) => { this.setDatevalue(val) }}
                                skills={(val: string[]) => { this.setSkillValue(val) }}
                                postBy={(val: string) => { this.setPostedByValue(val) }}
                                disableButton={(val: boolean) => { this.setButtonState(val) }}
                                experience={(val: string[]) => { this.setExperienceValue(val) }}
                                ref={this.childRef}
                                experienceSelect={(val: string[]) => { this.setExperienceCheckValue(val) }}
                            />
                        </Grid>
                    </Grid>
                    <CustomScrollBox style={{ marginTop: '10px' }}>
                        {this.state.candidateList.map((candidate) => {
                            return (
                                <Box sx={{
                                    border: '2px solid #F4F4F4',
                                    borderRadius: '16px',
                                    padding: '12px',
                                    marginTop: '10px',
                                    marginBottom: '0px',
                                    maxWidth: '239px',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    paddingTop: "6px",
                                }}>
                                    <Box style={{ display: 'flex', alignItems: "center" }}>
                                        <Box>
                                            <Avatar style={{ width: "49px", height: "49px", border: "3px solid rgb(244, 244, 244)" }} src={candidate.attributes.photo} />
                                        </Box>
                                        <Box style={{ marginLeft: "15px", maxWidth: "calc(100% - 82px)" }}>
                                            <Tooltip title={this.handleThreeCondition((candidate.attributes.full_name.length < 15), '', candidate.attributes.full_name)}>
                                                <Typography data-test-id="recruiterList" onClick={() => this.handleRecruiterNavigation(candidate.attributes.account_details.id)} style={webStyle.candidateName}>{candidate.attributes.full_name}</Typography>
                                            </Tooltip>
                                            {candidate.attributes.current_title && <Tooltip title={this.handleThreeCondition((candidate.attributes.current_title.length < 15), '', candidate.attributes.current_title)}>
                                                <Typography noWrap style={webStyle.title}>{candidate.attributes?.current_title}</Typography>
                                            </Tooltip>}
                                            {candidate.attributes?.country &&
                                                <Tooltip
                                                    title={(((candidate.attributes.country.length || 0) + (candidate.attributes.city.length || 0)) < 15) ?
                                                        '' :
                                                        `${candidate.attributes.country},${candidate.attributes.city}`
                                                    }>
                                                    <Typography noWrap style={webStyle.title}>{`${candidate.attributes.country}, ${candidate.attributes.city}`}</Typography>
                                                </Tooltip>
                                            }
                                        </Box>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0", flexWrap: "wrap" }}>
                                        <Typography data-test-id="potential_score" style={{ ...webStyle.viewAllText, color: "#6D97C1" }}>Potential Score: {Math.round(+candidate.attributes.total_potential_score)}%</Typography>
                                        <Typography style={{ ...webStyle.viewAllText, color: "#6D97C1" }}>|</Typography>
                                        <Typography data-test-id="percentile_score" style={{ ...webStyle.viewAllText, color: "#6D97C1" }}>
                                            Percentile Score: {Math.round(+candidate.attributes.percentile_score) || '0'}%
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography style={webStyle.title}>{this.renderAppliedTime(candidate.attributes.applied_job_details)}</Typography>
                                        <img style={{ width: "14px", height: "18px", cursor: "pointer" }} src={candidate.attributes.added_to_pipeline ? saveBlue : save} onClick={() => this.handleAddClick(candidate)} data-test-id="saveIcon" />
                                    </Box>
                                </Box >
                            )
                        })
                        }
                        {
                            this.state.currentPage < this.state.totalCandidatesPage &&
                            <Box style={{ textAlign: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={this.ScrollDown} data-test-id="scrollIcon">
                                <img src={scrollDown} alt="scroll-down" />
                            </Box>
                        }
                    </CustomScrollBox >
                    <ToastContainer />
                </Box >
            </>
            // Customizable Area End
        );
    }
}

export default LandingPageTalentPool;

// Customizable Area Start
const CustomScrollBox = styled(Box)({
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '87px',
    },
    '&::-webkit-scrollbar-track': {
        borderRadius: '57px',
        background: 'var(--Base-Grey-5, #D9D9D9)'
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#6C96C1', // Color of the scrollbar thumb
        borderRadius: '57px', // Border radius of the scrollbar thumb
        '&:hover': {
            background: '#3489e0', // Color of the scrollbar thumb on hover
        },
    },
});

const RootAutoComplete = styled(TextField)({
    display: 'flex',
    borderRadius: "8px",
    alignItems: 'center',
    position: 'relative',
    background: "#F4F4F4",
    "& .MuiInputBase-root": {
        border: "0px solid #9C9C9C",
        borderRadius: "8px",
        padding: '2.5px 5px !important'
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: '0px'
    },
    "& .MuiAutocomplete-input": {
        padding: "5.5px 4px !important"
    },
    "& .MuiSvgIcon-root": {
        display: 'none'
    },
    "& .custom-dropdown-icon": {
        height: '24px',
        width: '24px',
        backgroundSize: 'cover',
        backgroundImage: `url(${openMenuicon})`,
        right: 0,
        cursor: 'pointer',
        pointerEvents: 'none',
        display: 'none',
        position: 'absolute',
    },
    "& .MuiOutlinedInput-input": {
        borderRadius: "8px",
        width: "100%",
        border: "0px solid #9C9C9C",
        backgroundColor: "transparent",
        color: "#6D97C1",
        background: "#FFF",
        padding: '5px',
        "fontFamily": "Arial",
        "fontStyle": "normal",
        "fontSize": "14px",
        "lineHeight": "22px",
        "fontWeight": "lighter",
        "&:focus": {
            border: "0px solid #1F497D",
        },
        "&:hover": {
            border: "0px solid #6D97C1",
        },
        "&::placeholder": {
            "color": "#64748B",
            "fontSize": "14px",
            "fontFamily": "Arial",
            "fontWeight": "lighter",
            "lineHeight": "22px",
            "fontStyle": "normal",
        },
        paddingLeft: "20px !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
        },
    },
    "& .MuiInputAdornment-positionStart": {
        position: "absolute",
        padding: "2px"
    },
    "& .MuiInputAdornment-positionEnd": {
        position: "absolute",
        right: "10px"
    },
})

const webStyle = {
    candidateName: {
        fontFamily: 'Arial',
        fontSize: '14px',
        fontWeight: 400,
        color: "#0F172A",
        cursor: 'pointer'
    },
    title: {
        cursor: 'default',
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: 400,
        color: "#9C9C9C"
    },
    jobTitle: {
        color: 'var(--Dark-text, #17365D)',
        fontFamily: 'Arial',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
    },
    viewAllText: {
        color: 'var(--Primary-color, #1F497D)',
        fontFamily: 'Arial',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '18px',
        cursor: 'default'
    }
}
// Customizable Area End
