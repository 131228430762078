Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.createContentType = "multipart/form-data"
exports.placeHolderEmail = "Email";
exports.followUserApiEndPoint = "bx_block_followers/follows";
exports.savePostEndpoint = "bx_block_communityforum/posts"
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.ignoreRquestEndPoint = "bx_block_friends/:connection_request_id/reject_connection_request"
exports.EmailAccountLoginBlock="EmailAccountLoginBlock"
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.patchMethod = "PATCH";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.updateUserDetailsEndPoint = "bx_block_profile/profiles";
exports.CompanyListApiEndPoint = 'bx_block_profile/profiles/company_listing'
exports.updateCompanyDetail = "bx_block_profile/profiles/update_company_details"
exports.selectedGroupContextApi = "bx_block_profile/profiles/select_group_context"
exports.currentlocationEndPoint = 'bx_block_profile/profiles/current_location'
exports.uploadResumeApiEndPoint = 'bx_block_profile/profiles/upload_resume'
exports.uploadCoverLetterApiEndPoint = 'bx_block_profile/profiles/upload_cover_letter'
exports.upvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.downvoteEndpoint = "bx_block_upvotedownvote/upvotedownvotes/downvote";
exports.editPostApi = "bx_block_posts/posts"
exports.savePostApi = "bx_block_communityforum/posts"
exports.deletePostApi = "bx_block_posts/posts"
exports.selectTopicsApi = "bx_block_categories/sub_categories/sub_category_dropdown"
exports.workExperienceApi = "bx_block_profile/profiles/work_experiences"
exports.skillsListingApi = "bx_block_joblisting/jobs/skills_listing"
exports.addNewSkillApi = "bx_block_profile/profiles/add_skill"
exports.removeSkillApi = "bx_block_profile/profiles/remove_skill"
exports.postMethod = "POST";
exports.putMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.sendInvitationEndpoint = "bx_block_friends/create_connection_request";
exports.getCommentsEndPoint = "bx_block_comments/comments"
exports.baseURL = require('../../../framework/src/config')
exports.userProfileApi= "bx_block_contentflag/contents/:connection_id/user_profile_details"
exports.userPostApi= "bx_block_contentflag/contents/:connection_id/user_account_activities?"
exports.userDealApi= "/bx_block_contentflag/contents/:connection_id/user_deal_experiences?page=1&per_page=10"
exports.unFollowApisApiEndPoint = "bx_block_followers/follows"
exports.addFollowersApiEndPoint = "bx_block_followers/follows"
exports.addConnectionsApiEndPoint ="bx_block_friends/create_connection_request"
exports.withdrawConnectionEndPoint ="bx_block_friends/:connection_request_id/withdraw_connection_request"
exports.removeConnectionRequest ="bx_block_friends/:connection_request_id/remove_connection"
exports.getCommentOfPostEndPoint = "/bx_block_comments/comments?commentable_id=:comment_id&commentable_type=BxBlockPosts::Post"
exports.resonEndPointApi ="bx_block_contentflag/contents/get_flag_reasons"
exports.addReasonsApiEndPoint ="bx_block_contentflag/contents"
exports.removeInvitationEndpoint = "bx_block_friends";
exports.getDealExperienceEndPoint = "bx_block_portfolio_management/deal_experiences"
exports.contentManagementApi = "bx_block_content_management/content_managements"
exports.candidateProfileApiEndPoint = "/bx_block_cfuserprofilechatbot4/chatbots/save_candidate_profile"
exports.dealEquityChatBotApiEndPoint = "bx_block_portfolio_management/deal_experiences/create_equity_deal_experience"
exports.dealDebtChatBotApiEndPoint = "/bx_block_portfolio_management/deal_experiences/create_debt_deal_experience"
exports.sendOtpApiEndpoint = "/bx_block_profile/profiles/send_email_otp"
exports.verifyEmailApiEndpoint = "/bx_block_profile/profiles/verify_email_otp"
exports.sendOTPEndPoint = "/bx_block_profile/profiles/send_email_otp"
exports.verifyOTPApiEndPoint = "/bx_block_profile/profiles/verify_email_otp"
exports.updateRecruiterProfileApi = "/bx_block_cfuserprofilechatbot4/chatbots/save_recruiter_profile"
exports.userCompanyApi = "/bx_block_profile/profiles/get_company_details"
exports.getgraphDataApiEndPoint = "/bx_block_cfscorecalculations/scores/user_profile_progress"
exports.getgraphDataByCountryApiEndPoint ="/bx_block_cfscorecalculations/scores/user_profile_score_analysis"
exports.companyIndustryList = [
  { id: 0, name: "Aerospace and Defense" },
  { id: 1, name: "Agribusiness" },
  { id: 2, name: "Automotive & Mobility" },
  { id: 3, name: "Chemicals" },
  { id: 4, name: "Consulting" },
  { id: 5, name: "Consumer Products" },
  { id: 6, name: "Energy and Natural Resources" },
  { id: 7, name: "Financial Services" },
  { id: 8, name: "Forest Products, Paper & Packaging" },
  { id: 9, name: "Healthcare" },
  { id: 10, name: "Industrials" },
  { id: 11, name: "Law / Legal" },
  { id: 12, name: "Media" },
  { id: 13, name: "Oil & Gas" },
  { id: 14, name: "Other (please specify)" },
  { id: 15, name: "Private Equity" },
  { id: 16, name: "Public Sector" },
  { id: 17, name: "Retail" },
  { id: 18, name: "Social Sector (i.e., non-profit)" },
  { id: 19, name: "Technology" },
  { id: 20, name: "Telecommunications" },
  { id: 21, name: "Utilities & Alternative Energy" }
];
exports.chartOptions = {
  chart: {
    height: 350,
    type: 'radar',
    toolbar: {
      show: false
    }
  },
  plotOptions: {
      radar: {
        polygons: {
          strokeColors: '#F1F1F1',
          connectorColors: "#FFFFFF",
          fill: {
            colors: ['#FFFFFF', "#FFFFFF"] 
          }
        }
      }
    },
  title: {
    text: '',
    
  },
  yaxis: {
      stepSize: 20,
      show: true,
      showAlways: false,
      showForNullSeries: true,
      seriesName: ["0", "20", "40"],
      tickAmount: 0,
      min: 0,
      max: 100,
      floating: true,
      labels: {
          show: false,
          style: {
              colors: new Array(20).fill("#F1F1F1"),
              fontSize: '5px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 700,
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
      },
      title: {
          text: undefined,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 700,
          },
      },           
  },
  xaxis: {
    categories: ['', '', '', '', '', ''],
    labels:{
      rotate: 0,
      formatter: (value) => {
        return value.split(" ")?.map(word => word.charAt(0).toUpperCase()).join("");
      },
      tooltip: {
        enabled: true,
        formatter: (value) => value
      },
      show: true,
          style: {
              colors: new Array(20).fill("#17365D"),
              fontSize: '8px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 400,
          },
    }, 
  },
  fill: {
      opacity: 0.9,
      colors: ["#6B96C3"]
  },
  stroke: {
      show: true,
      width: 2,
      colors: ["#184581"],
      dashArray: 0
    },
  markers: {
      size: 3,
      hover: {
        size: 3
      },
      strokeWidth: 0,
      colors:["#184581"]
  },
  tooltip: {
    x: {
      formatter: (value) => value,
    },
    fixed: {
      position: "topRight"
    }
  }
}
exports.chartSeries = [{
  data: [0,0,0,0,0,0,],
  name: '',
}]
exports.info = `The
primary aim of this score is to
establish a more equitable
foundation, enabling individuals with
less traditional or junior backgrounds
to access opportunities in the fields
of investment banking, private equity.
growth equity, venture capital, and
family offices.`
// Customizable Area End

