import FilteritemsController, {
    Props,
    // Customizable Area Start
    // Customizable Area End
} from "./FilteritemsController";
// Customizable Area Start
import React from "react";
import { styled } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomCheckBoxComponent from "../../../components/src/CustomCheckBoxComponent";
import { Autocomplete } from "@material-ui/lab";
import AutoCompleteSearch from "../../../components/src/AutoCompleteSearch";
import { Country } from "country-state-city";
import { handleCityInputChangeUtils } from "../../../../packages/components/src/Utils.web";

export interface temp {
    name?: string
    menuName: string[]
}

// Customizable Area End

class Filteritems extends FilteritemsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={webStyle.root}>
                {this.state.tempData.map((item: any, index: number) => (
                    item.name &&
                    <AccordionComponent style={webStyle.accordian} key={index}>
                        <AccordionSummary
                            data-test-id="expandMoreIcon"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => this.initialRenderState(index)}
                        >
                            <Typography style={webStyle.menuTextAcc}>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {index === 1 &&
                                <div>
                                    <RootAutoComplete
                                        data-test-id="countryAutoComplete"
                                        id="combo-box-demo"
                                        options={Country.getAllCountries().map((country) => ({
                                            value: country.name,
                                            label: country.name,
                                            isoCode: country.isoCode
                                          }))}
                                        autoSelect
                                        onChange={(event, val) => { this.autoCompleteChange(val, item.name) }}
                                        getOptionLabel={(option: any) => option.label}
                                        value={this.checkIndexAutoComplete(index)}
                                        renderInput={(params) => <AutoCompleteSearch
                                            name="country"
                                            title=""
                                            params={params}
                                            placeHolder="Search"
                                        />}
                                    />
                                    {(this.state.selectedCountry) &&
                                        <div>
                                            <CustomCheckBoxComponent data-test-id="selectedCountry" defaultChecked onChange={() => this.clearAutoCompleteCheckBox(index)} value={this.checkIndexAutoComplete(index)} fontSize="12px" heightWidth="20px" borderRadius="6px" checkedColorBg="#17365D" title={`${this.checkIndexAutoComplete(index)}`} />
                                        </div>
                                    }
                                </div>
                            }
                            {(index === 2) &&
                                <div>
                                    <RootAutoComplete
                                        data-test-id="cityAutoComplete"
                                        id="combo-box-demo"
                                        options={this.state.optionCity || []}
                                        autoSelect
                                        onChange={(event, val) => { this.autoCompleteChange(val, item.name) }}
                                        getOptionLabel={(option: any) => option ? option.label || '' : ''}
                                        value={this.checkIndexAutoComplete(index)}
                                        renderInput={(params) => <AutoCompleteSearch
                                            name="city"
                                            title=""
                                            params={params}
                                            onChange={(event) => handleCityInputChangeUtils(event.target.value, (value: {label: string, value: string}[]) => this.setState({
                                                optionCity: value
                                            }) )}
                                            placeHolder="Search"
                                        />}
                                    />
                                    {(this.state.selectedCity) &&
                                        <div>
                                            <CustomCheckBoxComponent data-test-id="selectedCity" defaultChecked onChange={() => this.clearAutoCompleteCheckBox(index)} value={this.checkIndexAutoComplete(index)} fontSize="12px" heightWidth="20px" borderRadius="6px" checkedColorBg="#17365D" title={`${this.checkIndexAutoComplete(index)}`} />
                                        </div>
                                    }
                                </div>
                            }
                            {index === 4 &&
                                <div>
                                    <RootAutoComplete
                                        data-test-id="skillAutoComplete"
                                        id="combo-box-demo"
                                        // className={classes.rootAutoComplete}
                                        options={this.state.optionSkills || []}
                                        autoSelect
                                        onChange={(event, val) => { this.autoCompleteChange(val, item.name) }}
                                        getOptionLabel={(option: any) => option.label}
                                        value={this.checkIndexAutoComplete(index)}
                                        renderInput={(params) => <AutoCompleteSearch
                                            name="skill"
                                            title=""
                                            params={params}
                                            placeHolder="Search"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.autoCompleteListHandler(event, index)}
                                        />}
                                    />
                                    <div>
                                        {this.state.selectedSkill.map((itemSkill: string, indexSkill: number) =>
                                            <div key={indexSkill} style={{ display: 'block' }}>
                                                <CustomCheckBoxComponent data-test-id="selectedSkill" heightWidth="20px" borderRadius="6px" onChange={() => this.selectSkill(itemSkill)} checked={true} value={itemSkill} checkedColorBg="#17365D" title={`${itemSkill}`} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {item.menuName.map((i: string, indexChild: number) => (
                                <div key={indexChild} style={webStyle.lists}>
                                    {index === 0 &&
                                        <CustomCheckBoxComponent checked={this.state.selectedDate === i} value={i} onChange={(event, val) => this.autoCompleteChange(event, item.name)} fontSize="12px" heightWidth="20px" checkedColorBg="#17365D" title={`${i}`} borderRadius="6px" />
                                    }
                                    {index === 3 &&
                                        <CustomCheckBoxComponent checked={this.state.selectedPostedBy === i} value={i} onChange={(event, val) => this.autoCompleteChange(event, item.name)} fontSize="12px" heightWidth="20px" borderRadius="6px" checkedColorBg="#17365D" title={`${i}`} />
                                    }
                                    {index === 5 &&
                                        <CustomCheckBoxComponent checked={this.state.selectedExperianceCheck.includes(i)} value={i} onChange={(event, val) => this.autoCompleteChange(event, item.name)} fontSize="12px" heightWidth="20px" borderRadius="6px" checkedColorBg="#17365D" title={`${i}`} />
                                    }
                                </div>
                            ))}
                        </AccordionDetails>
                    </AccordionComponent>
                ))}
            </div>
            // Customizable Area End
        );
    }
}

export default Filteritems;

// Customizable Area Start
const webStyle = {
    root: {
        background: 'white',
        borderRadius: 20,
        '& .MuiAccordion-root.Mui-expanded': {
            margin: '0px'
        }
    },
    menuTextAcc: {
        color: 'var(--Dark-text, #17365D)',
        fontFamily: 'Arial',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px'
    },
    menuText: {
        fontSize: '0.85rem',
        color: '#17365D',
    },
    list: {
        margin: '10px 0px 10px 0px',
        padding: '6px 0px 6px 15px'
    },
    listSec: {
        margin: '10px 0px 10px 0px',
        padding: '6px 0px 6px 5px'
    },
    activeList: {
        margin: '10px 0px 10px 0px',
        background: '#F3F4F8',
        borderRadius: 20,
        padding: '6px 0px 6px 15px'
    },
    lists: {
        marginBottom: '5px',
        '& .MuiSvgIcon-root': { borderRadius: '20px' }
    },
    icons: {
        marginTop: '3px',
        height: '16px',
        width: '16px',
    },
    heading: {
        marginTop: '10px',
        marginLeft: '15px',
        fontSize: '0.95rem',
        color: '#17365D'
    },
    checkbox: {
        color: '#17365D',
        padding: '0px 10px'
    },
    accordian: {
        border: 'none',
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: '0px !important',
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px !important',
            margin: '-8px 0px !important'
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px !important',
            display: 'block',
            margin: '-8px 0px !important'
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '0px'
        },
    },
    icon: {
        borderRadius: 4,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#17365D',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#17365D',
        },
    },
}

const AccordionComponent = styled(Accordion)({
    border: 'none',
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    },
    '&$expanded': {
        margin: 0
    },
    '& .MuiAccordionSummary-content': {
        margin: '0px !important',
    },
    '& .MuiAccordionSummary-root': {
        padding: '0px !important',
        margin: '-5px 0px'
    },
    '& .MuiAccordionDetails-root': {
        padding: '0px !important',
        display: 'block'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '0px'
    },
});

const RootAutoComplete = styled(Autocomplete)({
    "& .MuiInputBase-root": {
        height: '32px !important',
        padding: "2.5px 5px !important"
    },
})
// Customizable Area End
