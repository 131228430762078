import React from "react";

// Customizable Area Start
import { Helmet } from 'react-helmet';
import {
  Tab,
  Tabs,
  Box,
  styled,
  Typography,
  Button,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import { dottedLine } from "./assets";
import { ToastContainer } from "react-toastify";
import { META_TAGS, handleTwoValues } from '../../../../packages/components/src/Utils.web';
import { DataListItemForConnection, DataListItemForFollowerForWeb, DataListItemForFollowingForWeb, DataListItemForReceive, DataListItemForSent } from "./FollowersController";
// Customizable Area End

import FollowersController, { Props, configJSON } from "./FollowersController";
import { borderRadius } from "react-select/src/theme";
import { log } from "util";

export default class Followers extends FollowersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={webStyle.upperMainBox}>
                <Helmet>
          <meta
            name="description"
            content={`
            Investment Banking Community: Connect and Thrive 
            Equity Research Network: Connect and Advance Here 
            Hiring for Investment Banking: Join Our Recruiting Platform 
            Recruit Equity Research Analysts: Join Our Network 
          `}
          />
          <meta
            name="keywords"
            content={META_TAGS}
          />

          <meta property="og:title" content="Join Our Platform for Private Equity Professionals" />
          <meta
            property="og:description"
            content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:title" content="Join Our Platform for Private Equity Professionals" />
          <meta
            name="twitter:description"
            content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
          />
        </Helmet>
        <Box style={webStyle.insideUpperMainBox}>
          <Box style={webStyle.leftBox}>
            <Typography style={webStyle.manageNetwork}>Manage My Network</Typography>
            <Box style={webStyle.navBox}>
              {this.state.networkNavigationArr.map((item: { image: string, name: string }, index: number) =>
                <Box key={index} style={this.state.activeNavTab === index ? webStyle.activeNavigationLink : webStyle.navigationLink}
                  onClick={() => this.handleNavChange(index)} data-test-id={`navigation${index}`}>
                  <img src={item.image} alt="InvitationIcon" />
                  <Typography style={webStyle.navColor}>
                    {item.name}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {this.state.activeNavTab === 0 &&
            <Box style={webStyle.mainBox}>
              {this.state.loading && <Box style={webStyle.insideInvitationBox}><CircularProgress /></Box>}
              {!this.state.loading && <>
              <Typography style={webStyle.invitationText}>Invitations</Typography>
              <TabsInput
                data-test-id="TabContainer"
                value={this.state.tabPanelNo}
                onChange={this.hanleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab data-test-id="ReceivedBtn" label="Received" id={"1"} />
                <Tab data-test-id="sentBtn" label="Sent" id={"2"} />
              </TabsInput>
              {this.state.tabPanelNo === 0 &&
                <Box style={webStyle.insideInvitationBox}>
                  {!this.state.loading && this.state.receivedInvitation.length === 0 && <Box style={webStyle.imageMainBoxNoData}>No Invitations Received</Box>}
                  {!this.state.loading && this.state.receivedInvitation.length > 0 && this.state.receivedInvitation.map((item: DataListItemForReceive, index: number) => (
                    <Box  style={webStyle.sentBox} key={index}>
                    <Box style={webStyle.imageMainBoxSent} key={index}>
                      <Box key={index} style={webStyle.imageBox}>
                        <Avatar
                        style={webStyle.receivedImage}
                        src={item.attributes.sender_details.data.attributes.profile_details.data.attributes.photo}
                        >
                        </Avatar>
                        <Box>
                          <Typography 
                              style={webStyle.imageTextOne}
                              data-test-id="receivedInvitationUser"
                              onClick={() => this.handleNavigate(Number(item.attributes.sender_details.data.attributes.profile_details.data.attributes.account_details.id))}
                          >
                            {item.attributes.sender_details.data.attributes.profile_details.data.attributes.full_name}
                          </Typography>
                          <Typography style={webStyle.imageTextTwo as React.CSSProperties}>
                              {this.renderCompanyName(
                                item.attributes.sender_details.data.attributes.role, 
                                item.attributes.sender_details.data.attributes.profile_details.data.attributes.current_title, 
                                item.attributes.sender_details.data.attributes.profile_details.data.attributes.current_company, 
                                item.attributes.sender_details.data.attributes.profile_details.data.attributes.company_name
                                )}
                            </Typography>
                          {item.attributes.mutual_connections.data.length > 0 &&
                              <Typography id="mutualConnections" style={webStyle.imageTextThree}> {item.attributes.mutual_connections.data[0].attributes.profile_details.data?.attributes.full_name} {this.renderMutualCount(item.attributes.mutual_connections.data.length - 1)}
                              </Typography>}
                        </Box>
                      </Box>
                      <Box style={webStyle.buttonBox}>
                        <Button style={webStyle.buttonIgnore} data-test-id="ignore" onClick={() => this.ignoreFriendRequestApiCall(item.attributes.id)}>Ignore</Button>
                        <Button style={webStyle.buttonAccept} data-test-id="accept" onClick={() => this.AcceptFriendRequest(item.attributes.id)}>Accept</Button>
                      </Box>
                    </Box>
                    {
                      item.attributes.note !== "" &&
                      <Box
                        style={webStyle.TextBox as React.CSSProperties}>
                        <Typography style={webStyle.TextBoxText as React.CSSProperties} data-test-id="messageone">
                          {item.attributes.note}
                        </Typography>
                      </Box>
                    }</Box>
                  ))}
                </Box>
              }
              </>}
              {this.state.tabPanelNo === 1 &&
                <Box style={webStyle.insideInvitationBox}>
                  {this.handleConditionRender(!this.state.loading && this.state.sentInvitations.length === 0, <Box style={webStyle.imageMainBoxNoData}>No Invitations Sent</Box>, "")}
                  {!this.state.loading && this.state.sentInvitations.length !== 0 && this.state.sentInvitations.map((item: DataListItemForSent, index: number) => (
                    <Box style={webStyle.sentBox} key={index}>
                      <Box style={webStyle.imageMainBoxSent}>
                        <Box key={index} style={webStyle.imageBox}>
                        <Avatar 
                         src={item.attributes.receiver_details.data.attributes.profile_details.data.attributes.photo}
                         style={webStyle.sentImage}>
                        </Avatar>
                          <Box style={{ width: "100%" }}>
                            <Typography 
                            data-test-id="userReceivedInvitation"
                            style={webStyle.imageTextOne}
                            onClick={() => this.handleNavigate(Number(item.attributes.receiver_details.data.attributes.profile_details.data.attributes.account_details.id))}
                            >
                              {item.attributes.receiver_details.data.attributes.profile_details.data.attributes.full_name}
                            </Typography>
                            {
                              <Typography style={webStyle.imageTextTwo as React.CSSProperties}>
                                {this.renderCompanyName(
                                  item.attributes.receiver_details.data.attributes.role,  
                                  item.attributes.receiver_details.data.attributes.profile_details.data.attributes.current_title, 
                                  item.attributes.receiver_details.data.attributes.profile_details.data.attributes.current_company, 
                                  item.attributes.receiver_details.data.attributes.profile_details.data.attributes.company_name
                                )}
                              </Typography>}
                            {item.attributes.mutual_connections.data.length > 0 &&
                              <Typography id="mutualConnections" style={webStyle.imageTextThree}> {item.attributes.mutual_connections.data[0].attributes.profile_details.data.attributes.full_name} {this.renderMutualCount(item.attributes.mutual_connections.data.length - 1)}
                              </Typography>}
                          </Box>
                        </Box>
                        <Button style={webStyle.withdrawButton} data-test-id="withdraw" onClick={() => this.withdrawSentRequest(item.attributes.id)}>Withdraw</Button>
                      </Box>
                      {item.attributes.note !== "" &&
                        <Box
                          style={webStyle.TextBox as React.CSSProperties }>
                          <Typography style={webStyle.TextBoxText as React.CSSProperties} data-test-id="messagetwo">
                            {item.attributes.note}
                          </Typography>
                        </Box>
                      }
                    </Box>
                  ))}
                </Box>
              }
            </Box>
          }
          {this.state.activeNavTab === 1 &&
            <Box style={webStyle.mainBox} data-test-id="connectionTab">
              {this.state.loading && <Box style={webStyle.insideInvitationBox}><CircularProgress /></Box>}
              {!this.state.loading && <>
                <Typography style={webStyle.connectionText}>{this.state.connectionList.length} Connections</Typography>
                <Box style={webStyle.insideInvitationBox}>
                  {this.handleConditionRender(!this.state.loading && this.state.connectionList.length === 0, <Box style={webStyle.imageMainBoxNoData}>No Connections</Box>, "")}
                  {!this.state.loading && this.state.connectionList.map((item: DataListItemForConnection, index: number) => {
                    const connectData = Number(item.attributes.current_user_id) === Number(item.attributes.sender_details.data.id) 
                    ? item.attributes.receiver_details 
                    : item.attributes.sender_details
                    return (
                      <Box style={webStyle.imageMainBox} key={index}>
                      <Box style={webStyle.imageBox}>
                      <Avatar 
                         src={connectData.data.attributes.profile_details.data.attributes.photo}
                         style={webStyle.ConnectionImage}
                         >
                        </Avatar>
                        <Box>
                            <Typography
                              style={webStyle.imageTextOne}
                              data-test-id="connectionUserName"
                              onClick={() => this.handleNavigate(connectData.data.attributes.profile_details.data.attributes.account_details.id)}
                            >
                          {connectData.data.attributes.profile_details.data.attributes.full_name}
                          </Typography>
                          <Typography style={webStyle.imageTextTwo as React.CSSProperties}>
                          {this.renderCompanyName(
                            connectData.data.attributes.role,  
                            connectData.data.attributes.profile_details.data.attributes.current_title, 
                            connectData.data.attributes.profile_details.data.attributes.current_company, 
                            connectData.data.attributes.profile_details.data.attributes.company_name)
                          }
                            </Typography>
                            {item.attributes.mutual_connections.data.length > 0 &&
                              <Typography id="mutualConnections" style={webStyle.imageTextThree}> 
                                {item.attributes.mutual_connections.data[0].attributes.profile_details.data?.attributes.full_name} {this.renderMutualCount(item.attributes.mutual_connections.data.length - 1)}
                              </Typography>}
                        </Box>
                      </Box>
                      <Box style={webStyle.buttonBox}>
                        <Button style={webStyle.buttonAccept
                        } data-test-id="messageClick" onClick={()=>this.handleMessageClick(connectData.data.attributes.profile_details)}>Message</Button>
                        <img src={dottedLine} data-test-id="dotLine" onClick={()=>{this.handleremoveConnection(item.id)}}/>
                        {Number(this.state.removeOpen) === Number(item.id) && (
                          <Box style={webStyle.removeBox} data-testId="removeConnection" onClick={() => this.removeConnection(item.attributes.id)}>
                            <Typography style={webStyle.removeText}>Remove Connection</Typography>
                            </Box>
                        )}
                      </Box>
                    </Box>
                    )
                  })}
                </Box>
              </>}
            </Box>
          }
          {this.handleConditionRender(this.state.activeNavTab === 2, <Box style={webStyle.mainBox}>
            {this.state.loading && <Box style={webStyle.insideInvitationBox}><CircularProgress /></Box>}
            {!this.state.loading && <>
            <TabsInput
              data-test-id="TabContainer"
              value={this.state.followTab}
              onChange={this.handleFollowTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab data-test-id="FollowingBtn" label="Following" id={"1"} />
              <Tab data-test-id="FollowerBtn" label="Followers" id={"2"} />
            </TabsInput>
            {this.state.followTab === 0 &&
              <Box>
                <Box>
                  <Typography style={webStyle.followingText}>You are following {this.state.userFollowerListDataForWeb.length} people out of your network</Typography>
                </Box>
                <Box style={webStyle.insideInvitationBox}>
                {this.state.userFollowerListDataForWeb.length === 0 && <Box style={webStyle.imageMainBoxNoData}>No Following</Box>}
                  {this.state.userFollowerListDataForWeb.map((item: DataListItemForFollowerForWeb, index: number) => (
                    <Box style={webStyle.imageMainBox} key={index}>
                      <Box style={webStyle.imageBox}>
                        <Avatar 
                         src={item.attributes.profile_details?.data?.attributes?.photo}
                         style={webStyle.FollowingImage} 
                         >
                        </Avatar>
                        <Box>
                          <Typography
                            style={webStyle.imageTextOne}
                            data-test-id="userFollowerListDataForClick"
                            onClick={() => this.handleNavigate(Number(item.attributes.profile_details.data.attributes.account_details.id))}
                          >
                          {item.attributes.profile_details?.data?.attributes?.full_name}
                          </Typography>
                          <Typography style={webStyle.imageTextTwo as React.CSSProperties}>
                            {this.renderCompanyName(
                              item.attributes.profile_details?.data?.attributes?.account_details?.role,
                              item.attributes.profile_details?.data?.attributes?.current_title,
                              item.attributes.profile_details?.data?.attributes?.current_company,
                              item.attributes.profile_details?.data?.attributes?.company_name)
                            }
                          </Typography>
                          <Typography style={webStyle.imageTextThree}>
                          {item.attributes.posts_created_this_week} post this week
                            </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.buttonBox}>
                        <Button style={webStyle.buttonAccept} data-test-id={`buttonAccept${index}`} onClick={() => this.unFollowFromFollowingApiCall(item.attributes.profile_details.data.attributes.account_details.id)}>Unfollow</Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            }
            {this.state.followTab === 1 &&
              <Box>
                <Box>
                  <Typography style={webStyle.followingText}>{this.state.userFollowingListDataFroWeb.length} people are following you</Typography>
                </Box>
                <Box style={webStyle.insideInvitationBox}>
                {this.state.userFollowingListDataFroWeb.length === 0 && <Box style={webStyle.imageMainBoxNoData}>No Followers</Box>}
                  {this.state.userFollowingListDataFroWeb.map((item:DataListItemForFollowingForWeb, index: number) => (
                    <Box style={webStyle.imageMainBox} key={index}>
                      <Box style={webStyle.imageBox}>
                      <Avatar 
                         src={item.attributes.profile_details.data.attributes.photo}
                         style={webStyle.FollowingImage}
                         >
                        </Avatar>
                        <Box>
                          <Typography
                            style={webStyle.imageTextOne}
                            data-test-id="userFollowingClick"
                            onClick={() => this.handleNavigate(Number(item.attributes.profile_details.data.attributes.account_details.id))}
                          >
                          {item.attributes.profile_details.data.attributes.full_name}
                            </Typography>
                          <Typography style={webStyle.imageTextTwo as React.CSSProperties}>

                          {this.renderCompanyName(
                              item.attributes.profile_details?.data?.attributes?.account_details?.role,
                              item.attributes.profile_details.data.attributes.current_title,
                              item.attributes.profile_details.data.attributes.current_company,
                              item.attributes.profile_details?.data?.attributes?.company_name)
                            }
                            </Typography>
                          <Typography style={webStyle.imageTextThree}>
                          {item.attributes.posts_created_this_week} post this week
                            </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.buttonBox}>
                        {item.attributes.is_followed === true
                        ? <Button style={webStyle.buttonAccept} data-test-id="unfollow" onClick={()=>this.unFollowFromFollowingApiCall(item.attributes.profile_details.data.attributes.account_details.id)}>Unfollow</Button> 
                        : <Button style={webStyle.buttonAccept} data-test-id="follow" onClick={()=>this.addFromFollowingApiCall(item.attributes.profile_details.data.attributes.account_details.id)}>Follow</Button>
                        }
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            }
            </>}
          </Box>, "")}
        </Box>
        <ToastContainer />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  upperMainBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
   },
   insideUpperMainBox:{
     height: "fit-content", 
     width: "1280px", 
     display: "flex",
     gap: "2.5%",
     paddingTop: "1%",
     justifyContent: "center"
    },
  leftBox: {
    background: "#FFFFFF",
    width: "calc(30% - 3.2%)",
    display:"flex",
    flexDirection:"column" as "column" ,
    gap: "2%",
    height: "600px",
    borderRadius: "16px",
    padding: "10px 16px",
    '@media (max-width: 960px)': {
      padding: '0px 10px 0px 10px',
    }
  },
  receivedTab: {
    width: "100%",
    color: "#17365D",
    fontSize: "16px",
    fontFamily: "Arial",
    fontWeight: 700
  },
  receivedImage:{
    height:"61px",
    width:"61px",
    borderRadius:"30px"
  },
  sentTab: {
    width: "100%"

  },
  sentImage:{
    height:"61px",
    width:"61px",
    borderRadius:"30px"
  },
  ConnectionImage:{
    height:"61px",
    width:"61px",
    borderRadius:"30px"
  },
  FollowingImage:{
    height:"61px",
    width:"61px",
    borderRadius:"30px"
  },
  sentBox:{
    display: "flex", 
    flexDirection: "column" as "column", 
    width: "100%",
    alignItems: "flex-end",
    gap: "10px",
    borderBottom: "1px solid #F4F4F4",
    paddingBottom: "10px"
    },
  imageTextOne: {
    color: "#17365D",
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: "Arial",
    cursor: "pointer"
  },
  imageTextTwo: {
    color: "#9C9C9C",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Arial",
    wordBreak: "break-all"
  },
  imageTextThree: {
    color: "#9C9C9C",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Arial",
  },
  withdrawButton: {
    color: "#9C9C9C",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Arial",
    border: " 1px solid #9C9C9C",
    borderRadius: "20px",
    background: "#FFFFFF",
    textTransform: "capitalize" as "capitalize"
  },
  manageNetwork: {
    color: "#17365D",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Arial",
    padding: ".6% 1.9%",

  },
  mainBox: {
    width: "calc(60% - 3.2%)",
    height: "fit-content",
    padding: "1.6%",
    backgroundColor: "white",
    borderRadius: "16px",

  },
  invitationText: {
    color: "#17365D",
    fontSize: "20px",
    fontWeight: 700,
    width: "100%",
    fontFamily: "Arial"
  },
  connectionText:{
    color: "#17365D",
    fontSize: "20px",
    fontWeight: 700,
    width: "100%",
    fontFamily: "Arial"

  },
  insideInvitationBox: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    flexDirection: "column" as "column",
    gap: "15px",
    paddingTop:"6px"
  },
  imageMainBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    gap: "2%",
    borderBottom: "2px solid #F4F4F4"
  },
  imageMainBoxSent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    gap: "2%",
  },
  imageMainBoxNoData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: "10%",
    color: "rgb(23, 54, 93)",
    fontSize: "16px",
    fontFamily: "Arial",
  },
  imageBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    gap: "2%",
    padding: "1% 2%",
    maxWidth: "80%"
  },
  imageTypography: {
    colr: "#17365D",
    fontWeight: 400,
    fontSize: "20px",
    fontFamily: "Arial",
  },
  buttonBox: {
    width: "50%",
    display: "flex",
    gap: "6%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    fontFamily: "Arial",
    padding: "0.8% 1%",
    position:"relative" as "relative"
  },
  buttonIgnore: {
    color: "9C9C9C",
    border: "1px solid #9C9C9C",
    borderRadius: "20px",
    background: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "Arial",
    textTransform: "capitalize" as "capitalize",
  },
  buttonAccept: {
    color: "#1F497D",
    border: "1px solid #1F497D",
    borderRadius: "20px",
    background: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "Arial",
    textTransform: "capitalize" as "capitalize"
  },
  TextBox:{
    minHeight:"20%",
    width:"86%",
    borderRadius:"8px",
    border:" 1px solid #9C9C9C",
    padding:"10px 8px",
    color:"#9C9C9C",
    gap:"8px",
    whiteSpace:"pre-wrap"
  },
  TextBoxText: {
    color: "#9C9C9C",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all"
  },
  navBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "14px",

  },
  activeNavigationLink: {
    gap: "8px",
    display: "flex",
    background: "#E2E8F0",
    border: "1px solid #E2E8F",
    borderRadius: "8px",
    padding: "3px 19px",
    cursor: "pointer"
  },
  navigationLink: {
    gap: "12px",
    display: "flex",
    border: "1px solid #E2E8F",
    borderRadius: "10px",
    padding: "3px 19px",
    cursor: "pointer"
  },
  navColor:{
     color: "#6D97C1",
     fontSize: "14px",
     fontFamily: "Arial" 
    },
  followingText: {
    color: "#9C9C9C",
    fontSize: "16px",
    fontFamily: "Arial",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "10px"
  },
  removeBox:{
      height:"50%",
      borderRadius:"10px",
      boxShadow:"5px 10px 18px #888888",
      padding:"8px",
      position:"absolute" as "absolute",
      top:"45px",
      left:"72px",
      cursor: "pointer",
    backgroundColor: "white"
  },
  removeText:{
    color:"#1F497D",
    fontSize: "14px",
    fontFamily: "Arial",
  }
}
const TabsInput = styled(Tabs)({
  "& .MuiTab-root": {
    maxWidth: "50% !important"
  },
  "& .MuiTab-textColorPrimary": {
    width: "100%",
    color: "#9C9C9C",
    textTransform: "capitalize",
    fontSize: "16px",
    fontFamily: "Arial",
  },
  "& .Mui-selected": {
    color: "#17365D",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Arial",
  },
  "& .MuiTabs-indicator": {
    background: "#6D97C1",
  }
})
// Customizable Area End
