import React from 'react';
import { Helmet } from 'react-helmet';
import AdvancedSearchController, { Props } from './AdvancedSearchController';
import {
    Container,
    Box,
    Button,
    Paper,
    Typography,
    Grid,
    CircularProgress,
    Avatar,
    Divider,
    styled
} from "@material-ui/core";
import {
    showAllIcon,
    cloudUpload, groupAdd, workOutline, 
    backBtn, groupCharacter, backBtnBlue,
    bookmarkBorderJobs, charBubbleJobs, like, cardTravel, location, bookmark_saved, disliked
} from './assets';
import CustomPost from '../../../blocks/user-profile-basic/src/components/CustomPost.web';
import InvitationDialog from '../../../../packages/components/src/InvitationDialog.web';
import { ToastContainer } from 'react-toastify';
import { META_TAGS, handleConditionFunction } from '../../../../packages/components/src/Utils.web';
import { ReportedSuccessDialogue } from '../../../components/src/ReportedSuccessDialogue';
import ChatReportDialog from '../../../components/src/ChatReportDialogue';

const LeftPaper = styled(Paper)({
    '@media (max-width: 767px)': {
        display: "none !important"
    }
});

const MainContainer = styled(Container)({
    '@media (max-width: 767px)': {
        padding: '20px 20px 80px 20px !important',
        gap: "0px !important"
    }
})

class AdvancedSearchResults extends AdvancedSearchController {
    constructor(props: Props) {
        super(props);
    }

    renderDislikeButton = (itemId: number, isDisliked: boolean, dataId: string) => {
        return (<div
            data-test-id={dataId}
            style={{
                width: '15px', height: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                borderRadius: '8px', padding: '8.5px', backgroundColor: `${isDisliked ? 'rgba(31, 73, 125, 1)' : 'rgba(109, 151, 193, 0.2)'}`, cursor: "pointer"
            }}
            onClick={() => this.handleUnlike(itemId, isDisliked)}
        >
            <img
                src={isDisliked ? disliked : like} alt="like" style={{ width: "21px", height: "21px" }}
            />
        </div>)
    }

    renderSaveButton = (itemId: number, isSaved: boolean, dataId: string) => {
        return (
            <div
                onClick={() => this.handleSave(itemId, isSaved,)}
                style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', width: '15px', height: '15px',
                    backgroundColor: `${isSaved ? 'rgba(31, 73, 125, 1)' : 'rgba(109, 151, 193, 0.2)'}`,
                    borderRadius: '8px', padding: '8.5px', cursor: "pointer"
                }}
                data-test-id={dataId}
            >
                <img alt="bookmarkBorderJobs" src={isSaved ? bookmark_saved : bookmarkBorderJobs} />
            </div>
        )
    }

    noResultFound = () => {
        return this.hasNoResultFound() ?
            (<div>
                    <Container style={{
                        display: 'flex', justifyContent: 'center',
                        alignItems: 'center', padding: '20px 40px 20px 20px'
                    }}>
                        <Box style={{
                            display: 'flex', flexDirection: 'column',
                            gap: '20px', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <img
                                src={groupCharacter}
                                alt="groupCharacter"
                            />
                            <Typography
                                style={{
                                    height: '26px',
                                    color: '#17365D', fontFamily: 'Arial',
                                    fontWeight: 700, fontSize: '18px', fontStyle: 'bold',
                                    textAlign: 'center', marginBottom: "-5px"
                                }}
                            > No results found
                            </Typography>
                            <Typography
                                data-test-id="goBackToAllResult"
                                onClick={() => this.goBack()}
                                style={{
                                    width: 'fit-content', height: '24px',
                                    color: 'rgba(109, 151, 193, 1)', fontFamily: 'Arial',
                                    fontWeight: 700, fontSize: '16px', fontStyle: 'bold',
                                    textAlign: 'center', cursor: 'pointer', display: "flex", alignItems: "center"
                                }}
                            > 
                            <img src={backBtnBlue} style={{ marginRight: "8px"}} />
                            {"Back to Search"}
                            </Typography>
                        </Box>
                    </Container>
                </div>
            ) :
            <Grid container style={{ display: "flex", width: "100%", justifyContent: "center", margin: "auto" }} id="loader-container">
                <CircularProgress style={{ color: '#1F497D' }} />
            </Grid>
    }

    renderShowAllResultSection = () => {
        return (this.showAllResults() ? (
            <LeftPaper
                style={{
                    display:'flex', flexDirection:'column', minWidth:'272px',
                    width:'272px', height:'129px', 
                    padding:'20px 16px', gap:'8px', 
                    backgroundColor:'#FFFFFF', justifyContent:'space-between', 
                    borderRadius: '16px'
                }}
            >   <div style={{
                    width:'100%',
                    color:'#17365D', fontFamily:'Arial',
                    fontWeight:400, fontSize:'10px',
                }}>
                    ON THIS PAGE
                </div>
                <Divider style={{ height: "1px", width: "100%", backgroundColor: "rgba(243, 244, 248, 1)" }} />
                <div data-test-id="leftListPost" onClick={() => this.handleRedirection("post")} 
                    style={{display:'flex', gap:'10px', alignItems: "center"}}
                >
                    <img src={cloudUpload} alt="post" />
                    <div style={{
                        width:'100%',
                        color:'#17365D', fontFamily:'Arial',
                        fontWeight:400, fontSize:'10px', cursor:'pointer'
                    }}>
                        Posts
                    </div>
                </div>
                <div data-test-id="leftListPeople"
                    onClick={() => this.handleRedirection("people")}
                    style={{display:'flex', gap:'10px', alignItems: "center"}}
                >
                    <img src={groupAdd} alt="people" />
                    <div style={{
                        width:'100%',
                        color:'#17365D', fontFamily:'Arial',
                        fontWeight:400, fontSize:'10px', cursor:'pointer'
                    }}>
                        People
                    </div>
                </div>
                <div data-test-id="leftListJobs"
                    onClick={() => this.handleRedirection("jobs")}
                    style={{display:'flex', gap:'10px', alignItems: "center"}}
                >
                    <img src={workOutline} alt="jobs" />
                    <div style={{
                        width:'100%',
                        color:'#17365D', fontFamily:'Arial',
                        fontWeight:400, fontSize:'10px',  cursor:'pointer'
                    }}>
                        Jobs
                    </div>
                </div>
            </LeftPaper>
            ) : (
                <Box
                    style={{
                        width:'180px', height:'169px', 
                        padding:'20px 16px', gap:'8px', 
                    }}
                > </Box>
            ))
    }

    renderPosts = () => {
        if (this.shouldDisplayPost() && !this.showAllResults() && this.noPostFound()) {
            return (<Paper
                style={{
                    display: 'flex', flexDirection: 'column',
                    flexGrow: 1, height: 'fit-content',
                    padding: '14px 0px',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px', width: this.handleCondition(this.showAllResults(), '100%', '93%'),
                    justifyContent: "center"
                }}>
                <Typography
                    style={{
                        height: '26px',
                        color: '#17365D', fontFamily: 'Arial',
                        fontWeight: 400, fontSize: '16px', fontStyle: 'bold',
                        textAlign: 'center'
                    }}
                > No results found
                </Typography>
                </Paper>)
        }

        return (this.shouldDisplayPost()) && (
            <Paper
                data-test-id="shouldDisplayPost"
                style={{
                    display: 'flex', flexDirection: 'column',
                    flexGrow: 1, height: 'fit-content',
                    padding: '14px 0px', 
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px', width: `${this.showAllResults() ? '100%' : '93%'}`, 
                    justifyContent: "center"
                }}>
                {this.shouldDisplayCustomPost() ?
                    <CustomPost
                        isFullPage
                        data-test-id="postsList1"
                        posts={this.state.getAllSearchResults! && this.state.getAllSearchResults.posts.data}
                        userDetails={this.state.userDetails}
                        activityType={"posts"}
                        upvotePost={this.upvotePost}
                        downvotePost={this.downvotePost}
                        toggleComments={this.toggleComments}
                        postShowID={this.state.postShowID}
                        createComment={this.createComment}
                        upvotePostComment={this.upvotePostComment}
                        downvotePostComment={this.downvotePostComment}
                        openConfirmDialog={this.handlePostMenu}
                        openEditFeedModal={() => { }}
                        handleSavePost={this.handleSavePostApi}
                        getInitials={() => ""}
                        isAdvanceSearch
                        progressComment = {this.state.progressComment}
                        remainingTimeComment = {this.state.remainingTimeComment}
                        loaderComment = {this.state.loaderComment}
                        handleDeleteComment={this.handleDeleteComment}
                        updateComment={this.updateComment}    
                        showReadMore
                        expandedPosts={this.state.expandedPosts}
                        toggleReadMore={this.toggleReadMore}
                        handleNameClick={this.handleRecruiterNavigation}
                    />
                    :
                    <>
                        <Typography
                            style={{
                                padding: '0px 20px',
                                color: '#17365D', fontFamily: 'Arial',
                                fontWeight: 700, fontSize: '20px', fontStyle: 'bold'
                            }}
                        > Posts
                        </Typography>
                        {this.noPostFound() ?
                            <Typography
                                style={{
                                    height: '26px',
                                    color: '#17365D', 
                                    fontFamily: 'Arial',
                                    fontWeight: 400, 
                                    fontSize: '16px', 
                                    fontStyle: 'bold',
                                    textAlign: 'center'
                                }}
                            > No results found
                            </Typography> :
                            <CustomPost
                                isFullPage
                                data-test-id="postsList2"
                                posts={this.state.getAllSearchResults! && this.state.getAllSearchResults.posts.data.slice(0, 3)}
                                userDetails={this.state.userDetails}
                                activityType={"posts"}
                                upvotePost={this.upvotePost}
                                toggleComments={this.toggleComments}
                                downvotePost={this.downvotePost}
                                postShowID={this.state.postShowID}
                                upvotePostComment={this.upvotePostComment}
                                createComment={this.createComment}
                                downvotePostComment={this.downvotePostComment}
                                openEditFeedModal={() => { }}
                                openConfirmDialog={this.handlePostMenu}
                                getInitials={() => ""}
                                handleSavePost={this.handleSavePostApi}
                                isAdvanceSearch
                                progressComment = {this.state.progressComment}
                                loaderComment = {this.state.loaderComment}
                                handleDeleteComment={this.handleDeleteComment}
                                remainingTimeComment = {this.state.remainingTimeComment}
                                updateComment={this.updateComment}    
                                showReadMore
                                expandedPosts={this.state.expandedPosts}
                                toggleReadMore={this.toggleReadMore}
                                handleNameClick={this.handleRecruiterNavigation}
                                handleRecruiterNavigation ={this.handleRecruiterNavigation}
                            />
                        }
                    </>
                }
                {(this.showAllPostText()) && (
                    <Box
                        data-test-id="showAllPosts"
                        onClick={() => this.handleRedirection("post")}
                        style={{ display: 'flex', justifyContent: 'end', cursor: 'pointer', paddingRight: "16px", marginTop: "-14px" }}
                    >
                        <Typography
                            style={{
                                color: '#1F497D', fontFamily: 'Arial',
                                fontWeight: 400, fontSize: '14px'
                            }}
                        > {"Show all posts"}
                        <img src={showAllIcon} style={{marginLeft: "13px"}}/>
                        </Typography>
                    </Box>
                )}
            </Paper>
        )
    }

    renderNoResultForJob = () => {
        return (this.noJobFound() && <Typography
            style={{ 
                height: '26px', 
                color: '#17365D', 
                fontFamily: 'Arial',
                fontWeight: 400, 
                fontSize: '16px',
                fontStyle: 'bold',
                textAlign: 'center'
            }}
        > No results found
        </Typography>)
    }

    renderNoResultForPeople = () => {
        return (this.noPeopleFound() && <Typography
            style={{
                height: '26px',
                color: '#17365D', fontFamily: 'Arial',
                fontWeight: 400, fontSize: '16px', fontStyle: 'bold',
                textAlign: 'center'
            }}
        > No results found
        </Typography>)
    }

    renderShowAllJobsText = () => {
        return (this.state.showAllPost && this.state.showAllJobs && this.state.showAllPeople) && (
            <Box
                data-test-id="showAllJobs"
                onClick={() => this.handleRedirection("jobs")}
                style={{ display: 'flex', justifyContent: 'end', cursor: 'pointer', paddingRight: "16px", marginTop: "10px" }}
            >
                <Typography
                    style={{
                        color: '#1F497D', fontFamily: 'Arial',
                        fontWeight: 400, fontSize: '14px'
                    }}
                > {this.state.getAllSearchResults && this.state.getAllSearchResults.jobs && this.state.getAllSearchResults.jobs.data.length > 0 && <>Show all jobs<img src={showAllIcon} style={{marginLeft: "13px"}}/></>}
                </Typography>
            </Box>)
    }

    renderShowAllPeopleText = () => {
        return (this.showAllResults()) && (
            <Box
                data-test-id="showAllPeople"
                onClick={() => this.handleRedirection("people")}
                style={{ display: 'flex', justifyContent: 'end', cursor: 'pointer', paddingRight: "16px", marginTop: "10px" }}
            >
                <Typography
                    style={{
                        color: '#1F497D', fontFamily: 'Arial',
                        fontWeight: 400, fontSize: '14px'
                    }}
                > {this.state.getAllSearchResults && this.state.getAllSearchResults.accounts && this.state.getAllSearchResults.accounts.data.length > 0 && <>Show all people<img src={showAllIcon} style={{marginLeft: "13px"}}/></>}
                </Typography>
            </Box>
        )
    }

    renderNotShow = () => {
        return (
            this.notShowAllResults() && (
                <Box
                    style={{
                        display: 'flex', gap: '8px', width: 'fit-content',
                        alignItems: 'center'
                    }}>
                    <img
                        data-test-id="backButton"
                        onClick={() => this.handleRerender()}
                        src={backBtn}
                        alt="backBtn"
                        style={{ cursor: 'pointer' }}
                    />
                    <Typography
                        style={{
                            color: '#17365D', fontFamily: 'Arial',
                            fontWeight: 700, fontSize: '14px', fontStyle: 'bold'
                        }}
                    > {this.getDisplayText()}
                    </Typography>
                </Box>
            )
        )
    }

    render() {
        return (
            <>
              <Helmet>
          <meta
            name="description"
            content={`
            Hire Sales and Trading Professionals: Join Us Today 
            Market Analysis Recruitment: Join Our Platform 
            Sales and Trading Community: Connect and Excel Here 
            Market Analysis Professionals: Connect and Learn Here 
          `}
          />
          <meta
            name="keywords"
            content={META_TAGS}
          />
        </Helmet>
            {
                 this.hasSearchResults() ? (
            <MainContainer data-test-id="searchResultContainer" style={{display:'flex', justifyContent:'space-between', gap:'20px', padding:'20px 40px 80px 20px'}} id='pageContainer'>
                {this.renderShowAllResultSection()}
                <Box 
                    style={{
                        display:'flex', flexGrow:1, 
                        height:'auto', flexDirection:'column', gap:'20px',
                        justifyContent:'space-between'
                    }}> {this.renderNotShow()}
                        
                    {this.renderPosts()}
                    {this.shouldDisplayPeople() && (
                    <Paper
                        style={{
                            display:'flex', flexDirection:'column',
                            flexGrow:1, height:'fit-content', 
                            padding:'14px 0px', borderRadius: '8px',
                            backgroundColor:'#FFFFFF' , width: this.handleCondition(this.showAllResults(), '100%', '93%'),
                            justifyContent: "center"
                        }}>
                            {this.showAllResults() && 
                             <Typography
                                style={{padding:'0px 20px', marginBottom: "13px",
                                color:'#17365D', fontFamily:'Arial',
                                fontWeight:700, fontSize:'20px', fontStyle:'bold'
                            }}
                            > People
                            </Typography>}
                            {this.renderNoResultForPeople()}
                            {this.state.getAllSearchResults && this.state.getAllSearchResults.accounts && this.state.getAllSearchResults.accounts.data.map((item, resultIndex:number) => 
                            { const { photo, full_name, current_company, current_title, account_details } =     item.attributes.profile_details.data.attributes    
                            return (
                             (this.showAllResults()) ? (  
                                ([0, 1, 2].includes(resultIndex)) && (
                            <Box key={item.id} style={{display:'flex', flexDirection:'column', gap:'8px', padding:'0px 32px', justifyContent:'center'}}>
                                <Box
                                    style={{display:'flex', alignItems:'center', borderBottom:"1px solid #F4F4F4", padding: '8px 0' }}

                              >
                                    <Avatar
                                        style={{
                                            width:'61px', 
                                            height:'61px', 
                                            borderRadius:'100%',
                                            cursor:'pointer'
                                        }}
                                        onClick={()=>{this.handleRecruiterNavigation(+item.attributes.profile_details.data.attributes.account_details.id)}}
                                        src={photo!}
                                        >
                                    </Avatar>
                                    <Box
                                        style={{display:'flex', flexDirection:'column', width:'100%'}}
                                    >
                                        <Typography
                                            style={{width:'fit-content', cursor:'pointer',padding:'0px 16px', fontWeight:400, fontSize:'20px',color:'#17365D', fontFamily:'Arial'}}
                                            onClick={()=>{this.handleRecruiterNavigation(+item.attributes.profile_details.data.attributes.account_details.id)}}
                                        >  
                                        {full_name}
                                        </Typography>
                                        <Typography
                                            style={{width:'fit-content', padding:'2px 16px',
                                            color:'#9C9C9C', fontFamily:'Arial',
                                            fontWeight:400, fontSize:'16px',
                                        }}
                                        > {current_company}
                                        </Typography>
                                        <Typography
                                            style={{width:'fit-content', padding:'0px 16px',
                                            color:'#17365D', fontFamily:'Arial',
                                            fontWeight:400, fontSize:'14px',
                                        }}
                                        > {current_title}
                                        </Typography>
                                    </Box>
                                    <Button  
                                        variant="outlined"
                                        data-test-id="connectBtn1"
                                        style={{height:'32px', fontFamily:'Arial', color:'#1F497D',
                                            borderRadius:'20px', padding:'6px 10px', textTransform: 'capitalize', width: "84px",
                                            fontWeight:400, fontSize:'14px', border:'1px solid #1F497D',
                                        }}
                                        onClick={() => this.handleInvitationDialogCondition(item)}
                                    > 
                                    {this.getStatusText(item)}
                                    </Button>
                                </Box>    
                            </Box>
                            )) : (
                                <Box style={{display:'flex', flexDirection:'column', gap:'8px', padding:'0px 32px', justifyContent:'center'}}>
                                <Box
                                    style={{display:'flex', alignItems:'center', borderBottom:"1px solid #F4F4F4", padding: '8px 0'}}
                                >
                                    <Avatar style={{width:'61px', height:'61px', borderRadius:'100%'}}
                                        src={photo!}>
                                    </Avatar>
                                    <Box
                                        style={{display:'flex', flexDirection:'column', width:'100%'}}
                                    >
                                        <Typography
                                            style={{cursor: "pointer", width:'fit-content', padding:'0px 16px', color:'#17365D', fontFamily:'Arial', fontWeight:400, fontSize:'20px',
                                        }}
                                        onClick={() => this.handleRecruiterNavigation(account_details.id)}
                                        >  {full_name}
                                        </Typography>
                                        <Typography
                                            style={{width:'fit-content', padding:'2px 16px',
                                            color:'#9C9C9C', fontFamily:'Arial',
                                            fontWeight:400, fontSize:'16px',
                                        }}
                                        > {current_company}
                                        </Typography>
                                        <Typography
                                            style={{width:'fit-content', padding:'0px 16px',
                                            fontWeight:400, fontSize:'14px', color:'#17365D', fontFamily:'Arial',
                                        }}
                                        > {current_title}
                                        </Typography>
                                    </Box>
                                    <Button  variant="outlined"
                                        style={{height:'32px', fontFamily:'Arial', color:'#1F497D', fontWeight:400, fontSize:'14px', border:'1px solid #1F497D',
                                            textTransform: 'capitalize', width: "84px",
                                            borderRadius:'20px', padding:'6px 10px',
                                        }}
                                        onClick={() => this.handleInvitationDialogCondition(item)}
                                        data-test-id="connectBtn2"
                                    > {this.getStatusText(item)}
                                    </Button>
                                </Box>    
                            </Box>
                            )
                            )}
                            )}
                        {this.renderShowAllPeopleText()}
                    </Paper>
                    )}
                    {this.shouldDisplayJob() && (
                    <Paper
                        style={{
                            display:'flex', flexDirection:'column',
                            flexGrow:1, height:'fit-content', 
                            padding:'14px 0px', gap: "8px",
                            backgroundColor:'#FFFFFF', borderRadius: '8px', width: this.handleCondition(this.showAllResults(), '100%', '93%'),
                            justifyContent: "center"
                        }}>
                            {this.showAllResults() && 
                            <Typography
                                style={{padding:'0px 20px', marginBottom: "4px",
                                color:'#17365D', fontFamily:'Arial',
                                fontWeight:700, fontSize:'20px', fontStyle:'bold'
                            }}
                            > Jobs
                            </Typography>}
                            {this.renderNoResultForJob()}
                            { this.state.getAllSearchResults!.jobs.data.map((item:any, resultIndex:number) => 
                            {   const {created_at, job_title, company_name, experience_required, location : locationName  } = item.attributes;
                                return (
                            (this.showAllResults()) ? (  
                                ([0, 1].includes(resultIndex)) && (
                                <Box 
                                key={item.id}
                                style={{
                                    display:'flex', flexDirection:'column', 
                                    gap:'8px', margin:'0px 16px', borderRadius: "16px",
                                    justifyContent:'center', border:'1px solid #F4F4F4'
                                }}>
                                <div
                                    style={{
                                        width:'fit-content', backgroundColor:'#6D97C1',
                                        borderRadius:'0px 18px 18px 0px', padding:'4px 10px',
                                        color:'#FFF', fontFamily:'Arial',
                                        fontWeight:400, fontSize:'12px', marginTop: "12px"
                                    }}
                                >
                                    {`Posted: ${created_at}`}
                                </div>
                            
                            <Box 
                                style={{
                                    display:'flex', flexDirection:'column', 
                                    gap:'8px', padding:'0px 16px',
                                    justifyContent:'center',
                                }}>
                                <Box
                                    style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}
                                >
                                    <Box 
                                        style={{display:'flex', flexDirection:'column', gap:'2px'}}
                                    >
                                        <Typography
                                                style={{width:'fit-content',
                                                color:'#17365D', fontFamily:'Arial',
                                                fontWeight:700, fontSize:'18px', fontStyle:'bold'
                                            }}
                                            > {job_title}
                                        </Typography> 
                                        <Typography
                                                style={{width:'fit-content',
                                                color:'#9C9C9C', fontFamily:'Arial',
                                                fontWeight:400, fontSize:'12px'
                                            }}
                                            > {company_name}
                                        </Typography>   
                                    </Box>
                                        <Box
                                            style={{display:'flex', 
                                            gap:'5px'}}
                                        >
                                        <div
                                            data-test-id="chatButton"
                                            onClick={() => this.navigateToMessage()}
                                            style={{cursor: "pointer",
                                                width:'15px', height:'15px', display:'flex', alignItems:'center', justifyContent:'center',borderRadius:'8px', padding:'8.5px',backgroundColor:'rgba(109, 151, 193, 0.2)',}}
                                        >
                                            <img
                                                alt="charBubbleJobs"
                                                src={charBubbleJobs}
                                            />
                                        </div>
                                        {this.renderDislikeButton(item.id, item.attributes.is_disliked, "dislikeJobBtn")}                                        
                                        {this.renderSaveButton(item.id, item.attributes.is_saved, "saveJobBtn")}                                        
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{display:'flex', justifyContent:'flex-start', alignItems:'center', padding:'8px 16px'}}>
                                <Box style={{display:'flex', gap:'4px', justifyContent:'flex-start',
                                borderRight:'1px solid #6D97C1', width:'33%'}} >
                                    <img src={cardTravel} alt="cardTravel" />
                                    <div
                                        style={{fontFamily:'Arial', color:'rgba(109, 151, 193, 1)', fontWeight:400, fontSize:'14px',}} >
                                        {experience_required}
                                    </div>
                                </Box>
                                <Box style={{display:'flex', gap:'4px', justifyContent:'flex-start',
                                borderRight:'1px solid #6D97C1', width:'33%', padding:'0px 8px'}}>
                                    <div id="salary-from-to" style={{fontFamily:'Arial', color:'rgba(109, 151, 193, 1)', fontWeight:400, fontSize:'14px', marginLeft: '20px'}} >
                                    {this.getDiscloseText(item)}
                                    </div>
                                </Box>
                                <Box style={{display:'flex', gap:'4px', justifyContent:'flex-start', padding:'0px 8px', marginLeft: '20px'}}>
                                    <img src={location} alt="location" />
                                    <div style={{fontFamily:'Arial', color:'rgba(109, 151, 193, 1)', fontWeight:400, fontSize:'14px',}} >
                                        {locationName}
                                    </div>
                                </Box>
                            </Box>
                            </Box>
                        )) : (
                            <Box 
                                style={{
                                    display:'flex', flexDirection:'column', 
                                    justifyContent:'center', border:'1px solid #F4F4F4',
                                    gap:'8px', margin:'0px 16px', borderRadius: "16px",
                                }}>
                                <div
                                    style={{
                                        width:'fit-content', backgroundColor:'#6D97C1',
                                        color:'#FFF', fontFamily:'Arial',
                                        borderRadius:'0px 18px 18px 0px', padding:'4px 10px',
                                        fontWeight:400, fontSize:'12px', marginTop: "12px"
                                    }}
                                >
                                    {`Posted: ${created_at}`}
                                </div>
                            
                            <Box 
                                style={{
                                    display:'flex', flexDirection:'column', 
                                    justifyContent:'center',
                                    gap:'8px', padding:'0px 16px',
                                }}>
                                <Box
                                    style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}
                                >
                                    <Box 
                                        style={{display:'flex', flexDirection:'column', gap:'2px'}}
                                    >
                                        <Typography
                                                style={{width:'fit-content',
                                                fontWeight:700, fontSize:'18px', fontStyle:'bold',
                                                color:'#17365D', fontFamily:'Arial',
                                            }}
                                            > {job_title}
                                        </Typography> 
                                        <Typography
                                                style={{width:'fit-content',
                                                color:'#9C9C9C', fontFamily:'Arial',
                                                fontWeight:400, fontSize:'12px'
                                            }}
                                            > {company_name}
                                        </Typography>   
                                    </Box>
                                        <Box
                                            style={{display:'flex', gap:'5px'}}
                                        >
                                        <div
                                            onClick={() => this.navigateToMessage()}
                                            style={{cursor: "pointer",
                                                width:'15px', height:'15px',
                                                display:'flex', alignItems:'center', justifyContent:'center',
                                                borderRadius:'8px', padding:'8.5px',
                                                backgroundColor:'rgba(109, 151, 193, 0.2)',
                                            }}
                                        >
                                            <img
                                                src={charBubbleJobs}
                                                alt="charBubbleJobs"
                                            />
                                        </div>
                                        {this.renderDislikeButton(item.id, item.attributes.is_disliked, "jobDislikeAll")}                                        
                                        {this.renderSaveButton(item.id, item.attributes.is_saved, "jobSaveAll")} 
                                    </Box>
                                </Box>
                            </Box>
                            <Box 
                                style={{display:'flex', justifyContent:'flex-start', 
                                padding:'8px 16px', alignItems:'center', }}
                            >
                                <Box style={{display:'flex', gap:'4px', justifyContent:'flex-start',
                                borderRight:'1px solid #6D97C1', width:'33%'}} >
                                    <img
                                        src={cardTravel}
                                        alt="cardTravel"
                                    />
                                    <div
                                        style={{fontFamily:'Arial', color:'rgba(109, 151, 193, 1)',
                                        fontWeight:400, fontSize:'14px',}} 
                                    >
                                        {experience_required}
                                    </div>
                                </Box>
                                <Box style={{display:'flex', gap:'4px', justifyContent:'flex-start',
                                borderRight:'1px solid #6D97C1', width:'33%', padding:'0px 8px'}}>
                                    <div
                                        style={{fontFamily:'Arial', color:'rgba(109, 151, 193, 1)',
                                        fontWeight:400, fontSize:'14px', marginLeft: '20px'}} 
                                    >
                                        {this.getDiscloseText(item)}
                                    </div>
                                </Box>
                                <Box style={{display:'flex', gap:'4px', justifyContent:'flex-start', padding:'0px 8px', marginLeft: '20px'}}>
                                    <img
                                        alt="location"
                                        src={location}
                                    />
                                    <div
                                        style={{fontFamily:'Arial', color:'rgba(109, 151, 193, 1)',
                                        fontWeight:400, fontSize:'14px'}} 
                                    >
                                        {locationName}
                                    </div>
                                </Box>
                            </Box>
                            </Box>
                        ))
                                        }
                        )}
                        {this.renderShowAllJobsText()}
                    </Paper>
                    )}
                </Box>
                <InvitationDialog
                    data-test-id="invitationDialog"
                    open={this.state.openInvitationDialog.open}
                    handleClose={this.handleInvitationClose}
                    handleSendInvitation={this.handleInvite}
                    questionOne={"Add a note to your invitation?"}
                    questionTwo={`Personalize your invitation ${this.getUserName()} by adding a note. Members are more likely to accept invitations that include a note.`}
                />
                {handleConditionFunction(
                    this.state.reportClick,
                    <ChatReportDialog
                        data-test-id="ChatReportDialog"
                        handleClose={this.handleReportDialogue}
                        selectedReason={this.state.selectedReason}
                        handleSelect={this.handleSelect}
                        handleSubmit={this.addReasons1}
                        list={this.state.userReasons}
                        reportType="post"
                    />,
                    null
                )}

                {handleConditionFunction(
                    this.state.reportedSuccessfully,
                    <ReportedSuccessDialogue
                        handleClose={this.closeSuccessDialogue}
                        open={this.state.reportedSuccessfully}
                        type="post"
                    />,
                    null
                )}
                <ToastContainer/>
            </MainContainer>
            ) : this.noResultFound() 
            }</>
        )
    }
}

export default AdvancedSearchResults;