import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  Slider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Chip,
  Grid,
  TableCell,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  Paper,
  TableHead,
  Avatar,
  CircularProgress,
  Tooltip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import CustomTalentCheckBoxComponent from "../../../../packages/components/src/CustomTalentCheckBoxComponent";
import {
  imgFilter,
  imgArrowDownIcon,
  imgViewCircle,
  text,
  searchImg,
  imgCorrect,
  imgQuestion,
  imgDot,
  imgDotBlue,
  imgMessage,
  imgStop,
  hiddenIcon,
  imgButtonBack,
  imgSkillIcon,
  imgEditIcon,
  imgBottomRight,
  imgTableClockIcon,
  imgPdf,
  imgDownload,
  imgXlsx,
  noPipelineAccess,
} from "./assets";
import { ToastContainer } from "react-toastify";
import CustomMenu from "../../../components/src/CustomMenu";
import { handleConditionFunction, TruncateWithTooltip } from "../../../../packages/components/src/Utils.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const CustomSlider = withStyles({
  root: {
    color: "#1F497D",
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#FFFFFF",
    border: "3px solid",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },

    "& .MuiSlider-valueLabel span": {
      backgroundColor: "transparent !important",
      marginTop: "7px",
      marginRight: "18px",
    },
  },
  active: {},
})(Slider);

const TextFieldAutocomplete = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "0px solid",
      height: "32px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--black-5, #1C1C1C0D)",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--black-5, #1C1C1C0D)",
    },
  },
})(TextField);

const TextFieldOthers = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "0px solid",
      height: "29.04px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--black-5, #1C1C1C0D)",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--black-5, #1C1C1C0D)",
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: "4px",
    },
  },
})(TextField);

const CountryAutocomplete = withStyles({
  popper: {
    "& .MuiAutocomplete-option": {
      color: "var(--Primary-color, #1F497D)",
      fontFamily: "Arial",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      cursor: "pointer",
    },
    "& .MuiAutocomplete-paper": {
      borderRadius: "8px",
      background: "var(--Basic-White, #FFF)",
      boxShadow:
        "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
      margin: "10px",
      marginBottom: "20px",
      zIndex: 9999,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input":
      {
        padding: "10px 10px",
      },
  },
})(Autocomplete);

const CustomScrollBarBox = withStyles({  
  root: {
    "@media (max-width: 1024px)": {
      "height": "100% !important",
    },
    "&::-webkit-scrollbar": {
      width: "16px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#D9D9D9",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#1F497D",
      width: "16px",
      borderRadius: "50px",
      height: "367px !important",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#1F497D",
    },
  },
})(Box);

const PageContainerBox = withStyles({
  root: {
    "@media (max-width: 1024px)": {
        "display": "flex !important",
        "flexDirection": "column",
        "& .filterBox" : {
          "width": "100% !important",
          "height": "100% !important"
        }
    }
  }
})(Box);

const CustomTableContainer = withStyles({
  root: {
    "&::-webkit-scrollbar": {
      width: "4px",
      maxHeight: "0.5em",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#D9D9D9",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#17365D",
      width: "6px",
      borderRadius: "67px",
      height: "87px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#17365D",
    },
  },
})(TableContainer);

const CustomTextField = withStyles({
  root : {
      "& .MuiInputBase-root" : {
        "& .MuiInputBase-input" : {
          textAlign: "center",
          color: "#1F497D",
          appearance: "textfield"
      }
    },
  },
})(TextField);

// Customizable Area End

import CfrecruitersdashboardController, {
  CandidateDetails,
  InputValueType,
  Props,
  TalentPipelineCandidateDetails,
  configJSON,
} from "./CfrecruitersdashboardController";
import BootstrapTooltip from "../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";

export default class Cfrecruitersdashboard extends CfrecruitersdashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderLongTitle = (title: string, limit: number = 30) => {
    return (
      <BootstrapTooltip data-test-id="titleText" title={this.handleTwoCondition(title, "").length >= limit ? this.handleTwoCondition(title, "") : ""}>
        {
          <span>
            {TruncateWithTooltip(this.handleTwoCondition(title, ""), limit)}
          </span>
        }
      </BootstrapTooltip>
    )
  } 

  renderDashboard = () => {
    const { candidateProfileDashboard } = this.state;
    if(candidateProfileDashboard)
    return (
      <>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "14px 0px",
            }}
          >
            <Box style={{ display: "flex", gap: "18px", justifyContent: 'flex-end', maxWidth: "75%" }} data-test-id="detailPage">
              <Box style={{ borderRadius: "50%" }}>
                <Avatar
                  src={this.handleTwoCondition(candidateProfileDashboard.attributes.photo, '')}
                  style={{
                    borderRadius: "50%",
                    width: "52px",
                    height: "52px",
                    border: "2px solid #6D97C1",
                  }}
                >

                </Avatar>
              </Box>
              <Box>
                <Typography variant="h6" style={webStyle.canddateNameProfile}>
                  {this.handleTwoCondition(candidateProfileDashboard.attributes.full_name, '-')}
                </Typography>

                <Typography
                  variant="body2"
                  style={{...webStyle.candidtaeDiscription, wordBreak: "break-all"}}
                >
                  {this.renderLongTitle(candidateProfileDashboard.attributes.current_title)}
                </Typography>
                <Typography
                  variant="body2"
                  style={webStyle.candidtaeDiscription}
                >
                  {candidateProfileDashboard.attributes.city &&
                    candidateProfileDashboard.attributes.country &&
                    `${candidateProfileDashboard?.attributes.city}, `}
                  {candidateProfileDashboard?.attributes.country}
                </Typography>
              </Box>
            </Box>

            <Box id="candidateDetailBox" style={{ display: "flex", gap: "11px", flexWrap: 'wrap-reverse', justifyContent: 'flex-end', height: '40px' }} >
              <Box >
                <Tooltip title={handleConditionFunction(candidateProfileDashboard.attributes.is_hidden, "UnHide", "Hide")} >
                  <img
                    style={{ cursor: "pointer" }}
                    src={ handleConditionFunction(candidateProfileDashboard.attributes.is_hidden, hiddenIcon, imgStop)}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      this.state.tabsValue === 0 ? this.handleHideCondition(event, candidateProfileDashboard) : this.hidePipelineCandidate(event, candidateProfileDashboard?.pipelineId!)}
                    data-test-id="hideCandidateDetail"
                  />
                </Tooltip>
              </Box>

              <Box>
                <Tooltip title="Message">
                  <img data-test-id="messageButton2" style={{ cursor: "pointer" }} src={imgMessage} onClick={(event) => this.handleMessageClick(event, candidateProfileDashboard)} />
                </Tooltip>
              </Box>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}>
              <CustomMenu
                menuWidth = "182px"
                data-test-id="addCandidateToPipelineMenu"
                hideSelectedIcon
                buttonIcon={imgDotBlue}
                menuItems={[
                  { label: 'Save to Pipeline', onClick: () => this.addCandidateToPipeline(candidateProfileDashboard?.attributes.id), hideItem: this.state.tabsValue === 1 },
                  { label: 'Invite to Apply' },
                ]}
                handleInvitation={(jobId: number) => this.inviteCandidateToApply(candidateProfileDashboard?.attributes.id, jobId, "invited")}
                popoverMenuItems={this.state.allJobs}
                />
                </div>
            </Box>
          </Box>

          <Box style={{ marginLeft: "inherit" }}>
            <Typography variant="body2" style={webStyle.potentialScoreText} data-test-id="potential-rank-scores">
              Potential Score: &nbsp; &nbsp; {Math.round(candidateProfileDashboard.attributes.total_potential_score)} &nbsp; &nbsp; | &nbsp; &nbsp;
              Rank: &nbsp; &nbsp; {this.handleTwoCondition(candidateProfileDashboard.attributes.overall_rank, '-')}
            </Typography>
          </Box>

          <Box style={{ marginLeft: "inherit" }}>
            <Typography variant="body2" style={webStyle.appliedText}>
              {this.renderAppliedTime(candidateProfileDashboard.attributes.applied_job_details)}
            </Typography>
          </Box>
        </Box>

        {candidateProfileDashboard.attributes.skills.length > 0 &&
        <Box
          style={{
            paddingBottom: "10px",
            paddingTop: "10px",
            borderBottom: "1px solid var(--stroke, #F4F4F4)",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <img src={imgSkillIcon} alt="skill icon" />
            <Typography variant="h6" style={webStyle.skillsText}>
              Skills
            </Typography>
          </Box>
          <Box>
              {candidateProfileDashboard.attributes.skills.map((skill) => {
                return (
                  <Chip
                    key={skill}
                    style={webStyle.skillsChip}
                    label={skill}
                  />
                );
              })}
          </Box>
        </Box>
              }

        {this.state.candidateProfileDashboard!?.attributes.work_experiences.data.length > 0 &&
          <Box
          style={{
            ...webStyle.item,
            position: "relative",
            paddingBottom: "26px",
            borderBottom: "1px solid var(--stroke, #F4F4F4)",
          }}
        >
          <Box style={{ ...webStyle.personalDetailsTop, marginRight: "33px" }}>
            <Typography>
              <span style={webStyle.blockHeading}>Experience</span>
            </Typography>
          </Box>

          <CustomTableContainer
            style={webStyle.tableWrapper}
          >
            <Table
              style={{ minWidth: 650, borderCollapse: "collapse" }}
              aria-label="simple table"
            >
              <TableBody>
                {this.state.candidateProfileDashboard!?.attributes.work_experiences.data.length > 0 &&
                 this.state.candidateProfileDashboard?.attributes.work_experiences.data.map((experienceData, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        ...webStyle.tableItem,
                        width: "1%",
                        fontWeight: 400,
                        verticalAlign: "top",
                        padding: "0 6px",
                        paddingLeft: "0px",
                      }}
                    >
                      <span className="verticalLine"></span>
                      {this.handleCondition(index % 2 === 0, (
                        <span className="workExperienceColumn">
                          {index + 1}
                        </span>
                      ), (
                        <span
                          className="workExperienceColumn"
                          style={{ backgroundColor: "#C7D9F0" }}
                        >
                          {index + 1}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        fontWeight: 400,
                        padding: "5px",
                        paddingTop: "3px"
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "45px",
                        }}
                      >
                        <span style={webStyle.experienceTitle}>
                          {experienceData.attributes.position}
                        </span>
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: "8px",
                            lineHeight: "18px",
                            color: "#17365D",
                          }}
                        >
                          Specialization Sector: {experienceData.attributes.specialization}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        fontWeight: 400,
                        verticalAlign: "top",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <img
                          src={imgTableClockIcon}
                          style={{ marginRight: "5px" }}
                        />
                         <span style={{ fontFamily: "Arial" }}>
                           {`${this.formatDate(experienceData.attributes?.started_time)}
                                    ${this.handleCondition(experienceData.attributes.currently_working,
                               ` till Present`,
                               ` to ${(this.formatDate(this.handleTwoCondition(experienceData.attributes.ended_time , '')))}`)}
                                    `}
                         </span>
                       </span>
                     </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        cursor: "pointer",
                        verticalAlign: "top",
                        padding: "5px",
                        paddingRight: "20px",
                        paddingTop: "3px"
                      }}
                    >
                      <span
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                       >
                        {experienceData.attributes.company_icon_url &&
                          <img
                            alt="Edit icon"
                            src={experienceData.attributes.company_icon_url}
                            style={{ width: '18px', cursor: 'pointer', marginRight: '4px' }}
                          />
                        }
                         <span
                           style={{
                            marginRight: "10px",
                            marginLeft: "4px",
                            fontSize: "10px",
                            fontFamily: "Arial",
                          }}
                        >
                          {experienceData.attributes.company_name}
                        </span>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <img
            src={imgBottomRight}
            alt="icons"
            style={{
              position: "absolute",
              bottom: "0px",
              right: "0px",
              backgroundColor: "#FFFFFF",
            }}
          />
        </Box>}

        {this.handleAndCondition(this.state.candidateProfileDashboard!?.attributes.deal_experiences.data.length > 0,
        <Box
          style={{
            ...webStyle.item,
            position: "relative",
            paddingBottom: "26px",
            borderBottom: "1px solid var(--stroke, #F4F4F4)",
          }}
        >
          <Box style={{ ...webStyle.personalDetailsTop, marginRight: "33px", marginBottom: "10px" }}>
            <Typography>
              <span style={webStyle.blockHeading}>Deal Experience</span>
            </Typography>
          </Box>

          <CustomTableContainer
            style={webStyle.tableWrapper}
          >
            <Table
              style={{ minWidth: 650, borderCollapse: "collapse" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}>
                    Date
                  </TableCell>
                  <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}>
                    Bidder Company
                  </TableCell>
                  <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}>
                    Buyer/Lender
                  </TableCell>
                  <TableCell style={{ ...webStyle.tableItem, fontWeight: 700 }}>
                    Enterprise Value(m)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.candidateProfileDashboard!.attributes.deal_experiences.data.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        fontWeight: 400,
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                       {data.attributes.created_at}
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        fontWeight: 400,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      {this.handleCondition(data.attributes.deal_type === "debt", data.attributes.borrower_name, data.attributes.target_name)}
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        fontWeight: 400,
                        paddingTop: 0,
                        paddingBottom: 0,

                      }}
                    >
                      {this.handleCondition(data.attributes.deal_type === "debt", data.attributes.lender_name, data.attributes.buyer_investor_name)}
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.tableItem,
                        width: "16.67%",
                        fontWeight: 400,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      {this.handleCondition(data.attributes.deal_type === "debt", data.attributes.debt_size, data.attributes.enterprise_value)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <img
            src={imgBottomRight}
            alt="icons"
            style={{
              position: "absolute",
              bottom: "0px",
              right: "0px",
              backgroundColor: "#FFFFFF",
            }}
          />
        </Box>)
        }

        { (candidateProfileDashboard.attributes.resume || candidateProfileDashboard.attributes.cover_letter) && 
        <Box style={{ marginTop: "10px", paddingBottom: "100px" }}>
          <Typography
            variant="h6"
            style={{ ...webStyle.resumeText, marginBottom: "19px" }}
          >
            Resume
          </Typography>
          {candidateProfileDashboard?.attributes.resume?.name &&
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                style={{ ...webStyle.resumePdfText }}
              >
                {candidateProfileDashboard?.attributes.resume?.name}
              </Typography>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                data-test-id="resumeDownload"
                src={imgDownload}
                alt="pdf image"
                style={{ cursor: "pointer" }}
                onClick={this.handleDownloadResume(
                  candidateProfileDashboard?.attributes.resume
                )}
              />
            </Box>
          </Box>}
          {candidateProfileDashboard?.attributes.cover_letter?.name &&
           <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                style={{ ...webStyle.resumePdfText }}
              >
                {candidateProfileDashboard?.attributes.cover_letter.name}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <img
                src={imgDownload}
                alt="pdf image"
                style={{ cursor: "pointer" }}
                onClick={this.handleDownloadResume(
                  candidateProfileDashboard?.attributes.cover_letter
                )}
              />
            </Box>
          </Box>}
        </Box>}

      </>
    );
  };

  renderNoSubscriptionPage = () => {
    return (
      <Box style={{ ...webStyle.condidateBox, flexDirection: "column" }}>
        <img src={noPipelineAccess} />
        <Box style={webStyle.noTalentBottomBox}>
          <Typography style={webStyle.noTalentHeading}>No Talent Pipeline access</Typography>
          <Typography style={webStyle.noTalentText}>Unlock exclusive benefits like talent pipeline access with a subscription! Elevate your experience and enjoy premium features. {this.renderBtnLabel()} for more!</Typography>
          <Button 
            data-test-id="subscribeBtn2"
            style={webStyle.noTalentButton}
            onClick={(event) => this.handleSubscribeClick(event)}
          >
            {this.renderBtnLabel()}
          </Button>
        </Box>
      </Box>
    )
  }
  handleClick = (event: React.MouseEvent<HTMLElement>  , id :number) => {
    this.handleCandidates(event, true, id);
    this.userProfileClick(event, id);
  };
  
  renderContent = () => {
    const { tabsValue, candidateDetails } = this.state;
    if (tabsValue === 0) {
      return (
        <>
        <Box style={webStyle.condidateBox}>
                <Box>
                  <Typography variant="h6" style={webStyle.candidateCountText}>
                    {this.state.totalCandidates} Candidates
                  </Typography>
                </Box>

                <Box>
                  <FormControl
                    style={{
                      backgroundColor: "var(--black-5, #1C1C1C0D)",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    <TextFieldOthers
                      size="small"
                      variant="outlined"
                      placeholder="Search"
                      onChange={this.handelTalentPollTabSearch}
                      value={this.state.searchValue}
                      style={{ borderRadius: "8px", width: "208px" }}
                      InputProps={{
                        style: {
                          color: "#1F497D",
                          paddingBottom: "4px",
                          paddingTop: "4px",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={searchImg} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src={text} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
          <Box style={{ padding: "25px" }}>
            {candidateDetails.length > 0 && 
            candidateDetails?.map((item: CandidateDetails, index: number) => (
              <Box
                data-test-id="talentPoolTab"
                key={index}
                style={{
                  padding: "5px 20px",
                  marginBottom: "20px",
                  ...webStyle.cardContanier,
                  border: this.renderBorderColor(item)
                }}
                className="candidate-list-item"
                onClick={() => this.selectCardContainer(item.attributes.id)}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "13px 13px 14px 0px",
                  }}
                >
                  <Box style={{ display: "flex", gap: "18px", maxWidth:"75%" }}>
                    <Box style={{ borderRadius: "50%" }}>
                    <Avatar
                      src={item?.attributes?.photo}
                      style={{
                        borderRadius: "50%",
                        width: "54px",
                        height: "54px",
                    }}
                    >

                    </Avatar>
                    </Box>
                    <Box>
                      <Typography
                        data-test-id="candidateName"
                        variant="h6"
                        style={webStyle.candidtaeNameText}
                       
                        onClick={(event) => this.handleClick(event, item?.attributes?.id)}

                      >
                        {item.attributes.full_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        style={{...webStyle.candidtaeDiscription, wordBreak: "break-word"}}
                      >
                      {this.renderLongTitle(item?.attributes?.current_title)}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{...webStyle.candidtaeDiscription, wordBreak: "break-word"}}
                      >
                        {item?.attributes?.city &&
                          item?.attributes?.country &&
                          `${item.attributes.city}, `}
                        {item?.attributes?.country}
                      </Typography>
                    </Box>
                  </Box>

                  <Box id="talentPoolBox" style={{ display: "flex", gap: "11px", flexWrap: 'wrap-reverse', justifyContent: 'flex-end', alignItems: "flex-start", alignSelf: "flex-start" }}>
                    <Box>
                    <Tooltip title={handleConditionFunction(item.attributes.is_hidden, "UnHide", "Hide")} >
                        <img
                          style={{ cursor: "pointer" }}
                          src={handleConditionFunction(item.attributes.is_hidden, hiddenIcon, imgStop)}
                          onClick={(event: React.MouseEvent<HTMLElement>) => 
                              item.attributes.is_hidden ?
                              this.unHideCandidate(event, item.attributes.id) :
                              this.hideCandidate(event, item.attributes.id)
                          } 
                          data-test-id="hideIcon" />
                      </Tooltip>
                    </Box>

                    <Box>
                      <Tooltip title="Message">
                        <img data-test-id="messageButton3" style={{ cursor: "pointer" }} src={imgMessage} onClick={(event) => this.handleMessageClick(event, item)} />
                      </Tooltip>
                    </Box>
                    <CustomMenu
                      menuWidth="182px"
                      menuFontWeight={700}
                      data-test-id="applyJobMenu"
                      hideSelectedIcon
                      buttonIcon={imgDot}
                      menuItems={[
                        { label: 'Save to Pipeline', onClick: () => this.addCandidateToPipeline(item.attributes.id), hideItem: this.checkForTalentPipelineAdded(item.attributes.account_details.id) },
                        { label: 'Invite to Apply' },
                      ]}
                      popoverMenuItems={this.state.allJobs}
                      handleInvitation={(jobId: number) => this.inviteCandidateToApply(item?.attributes.id, jobId, "invited")}
                    />
                  </Box>
                </Box>

                <Box style={{ marginLeft: "inherit" }}>
                  <Typography
                    variant="body2"
                    style={webStyle.potentialScoreText}
                  >
                    Potential Score: &nbsp; &nbsp; {Math.round(item.attributes.total_potential_score)} &nbsp; &nbsp; | &nbsp;
                    &nbsp; Rank: &nbsp; &nbsp; {this.handleTwoCondition(item.attributes?.overall_rank , '-')}
                  </Typography>
                </Box>

                <Box style={{ marginLeft: "inherit" }}>
                  <Typography variant="body2" style={webStyle.appliedText}>
                  {this.renderAppliedTime(item.attributes.applied_job_details)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      );
    } else if (tabsValue === 1) {
      if(this.state.canAccessPipeline) {
      return (
        <>
          <Box style={webStyle.condidateBox}>
            <Box>
              <Typography variant="h6" style={webStyle.candidateCountText}>
                {this.state.totalCandidatesPipeline} Candidates
              </Typography>
            </Box>

            <Box>
              <FormControl
                style={{
                  backgroundColor: "var(--black-5, #1C1C1C0D)",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <TextFieldOthers
                  size="small"
                  variant="outlined"
                  placeholder="Search"
                  onChange={this.handelTalentPipelineTabSearch}
                  value={this.state.searchValuePipeline}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchImg} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={text} />
                      </InputAdornment>
                    ),
                    style: {
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      color: "#1F497D"
                    },
                  }}
                  style={{ borderRadius: "8px", width: "208px" }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box style={{ padding: "25px" }}>
            {this.state.talentPipelineCandidateDetails.length > 0 &&
             this.state.talentPipelineCandidateDetails.map((item : TalentPipelineCandidateDetails, index : number) => {
              const { candidate_detail } = item.attributes;

              return ( <Box
                data-test-id="candidateCardPipeline"
                key={index}
                style={{
                  padding: "5px 20px",
                  marginBottom: "20px",
                  ...webStyle.cardContanier,
                  border : this.renderBorderColor(item)
                }}
                className="candidate-list-item-pipeline"
                onClick={() => this.selectCardContainer(item.attributes.id)}  
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "13px 13px 14px 0px;",
                  }}
                >
                  <Box style={{ display: "flex", gap: "18px" , maxWidth:"75%"}}>
                    <Box style={{ borderRadius: "50%" }}>
                    <Avatar
                      src={this.handleTwoCondition(candidate_detail.data.attributes.photo, '')}
                      style={{
                        borderRadius: "50%",
                        width: "54px",
                        height: "54px",
                  }}
                    >
                    </Avatar>
                    </Box>
                    <Box>
                      <Typography
                        data-test-id="talentPipelineCandidateName"
                        variant="h6"
                        style={webStyle.candidtaeNameText}
                        onClick={(event) =>
                          this.handleCandidates(event, true, candidate_detail?.data?.attributes?.id)
                        }

                      >
                        {this.handleTwoCondition(candidate_detail.data.attributes.full_name, '-')}
                      </Typography>

                      <Typography
                        variant="body2"
                        style={{...webStyle.candidtaeDiscription, wordBreak: "break-word"}}
                      >
                      {this.renderLongTitle(candidate_detail.data.attributes.current_title)}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{...webStyle.candidtaeDiscription, wordBreak: "break-word"}}
                      >
                        {candidate_detail?.data?.attributes?.city &&
                          candidate_detail?.data?.attributes?.country &&
                          `${candidate_detail?.data?.attributes?.city}, `}
                        {candidate_detail?.data?.attributes?.country}                      
                      </Typography>
                    </Box>
                  </Box>

                  <Box id="talentPipelineBox" style={{ display: "flex", gap: "11px", flexWrap: 'wrap-reverse', justifyContent: 'flex-end' }}>
                    <Box >
                      <Tooltip title="Remove from Talent Pipeline">
                        <img style={{ cursor: "pointer" }} 
                        src={ handleConditionFunction(candidate_detail.data.attributes.is_hidden, hiddenIcon, imgStop)} 
                        onClick={(event: React.MouseEvent<HTMLElement>) =>  handleConditionFunction(candidate_detail.data.attributes.is_hidden, this.hidePipelineCandidate(event, item?.id), this.hidePipelineCandidate(event, item?.id)) } data-test-id="hideIconPipeline"
                        />
                      </Tooltip>
                    </Box>

                    <Box >
                      <Tooltip title="Message">
                        <img data-test-id="messageButton1" style={{ cursor: "pointer" }} src={imgMessage} onClick={(event) => this.handleMessageClick(event, candidate_detail.data)} />
                      </Tooltip>
                    </Box>

                    <Box >
                      <CustomMenu
                        data-test-id="reviewMenuPipeline"
                        tooltipTitle="Review"
                        hideSelectedIcon
                        buttonIcon={imgQuestion}
                        menuItems={this.state.allJobs.map((jobItem) => {
                          return {
                            label: jobItem.attributes.job_title,
                            onClick: () => this.inviteCandidateToApply(candidate_detail?.data?.attributes.id, jobItem.id, "review")
                          }
                        })}
                      />
                    </Box>

                    <Box >
                      <CustomMenu
                        data-test-id="interviewMenuPipeline"
                        tooltipTitle="Interview"
                        hideSelectedIcon
                        buttonIcon={imgCorrect}
                        menuItems={this.state.allJobs.map((jobItem) => {
                          return {
                            label: jobItem.attributes.job_title,
                            onClick: () => this.inviteCandidateToApply(candidate_detail?.data?.attributes.id, jobItem.id, "interviewing")
                          }
                        })}
                      />
                    </Box>

                     <CustomMenu
                      menuWidth="182px"
                      menuFontWeight={700}
                      data-test-id="applyJobMenuPipeline"
                      hideSelectedIcon
                      buttonIcon={imgDot}
                      menuItems={[
                        { label: 'Invite to Apply'},
                      ]}
                      handleInvitation={(jobId: number) => this.inviteCandidateToApply(candidate_detail?.data?.attributes.id, jobId, "invited")}
                      popoverMenuItems={this.state.allJobs}
                    />
                  </Box>
                </Box>

                <Box style={{ marginLeft: "inherit" }}>
                  <Typography
                    variant="body2"
                    style={webStyle.potentialScoreText}
                  >
                    Potential Score: &nbsp; &nbsp; {Math.round(candidate_detail.data.attributes.total_potential_score)} &nbsp; &nbsp; | &nbsp;
                    &nbsp; Rank: &nbsp; &nbsp; {candidate_detail?.data?.attributes.overall_rank ?? '-'}
                  </Typography>
                </Box>

                <Box style={{ marginLeft: "inherit" }}>
                  <Typography variant="body2" style={webStyle.appliedText}>
                  {this.renderAppliedTime(candidate_detail.data.attributes.applied_job_details)}
                  </Typography>
                </Box>
              </Box>)
            })}
          </Box>
        </>
      );
      } else {
        return (this.renderNoSubscriptionPage())
      }
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <PageContainerBox
        style={{
          display: "grid",
          gridTemplateColumns: "440px auto",
          width: "100%",
        }}
        id="pageContainer"
      >
        <Box style={{width:'421px'}} className="filterBox">
        <CustomScrollBarBox
          style={{
            height: "calc(-64px + 100vh)",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "#F2F6F9",
          }}
        >
          <Box display="flex" alignItems="center" style={webStyle.filterBox}>
            <Box style={{ display: "flex" }}>
              {" "}
              <img src={imgFilter} alt="filter" style={{marginRight: '10px'}} />
              <Typography variant="h6" style={webStyle.filterLable}>
                Filters
              </Typography>
            </Box>
            <Box data-test-id="filterExpandIcon" onClick={this.handleFiltersExpand} style={{cursor: "pointer"}} >
              {" "}
              <img src={imgArrowDownIcon} alt="down arrow" />
            </Box>
          </Box>
          {this.state.showFilters &&
          <>
          <Box style={webStyle.rankedBox}>
            <Box
              display="flex"
              alignItems="center"
              style={{ padding: "24px 24px 0" }}
            >
              <Typography variant="h6" style={webStyle.rankedLable}>
                Top Ranked Candidate
              </Typography>
              <CustomTextField
                data-test-id="rankInput1"
                style={webStyle.textfield}
                InputProps={{ disableUnderline: true, inputProps: { min: 0, max: this.state.maxSliderValue} }}
                value={this.renderMinValue(this.state.maxSliderValue - this.state.rankedValue[0])}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => this.handleChangeRankedValue(event, 0)}
                type="number"
              />
              <Typography variant="h6" style={webStyle.toText}>
                to
              </Typography>
              <CustomTextField
                data-test-id="rankInput2"
                style={webStyle.textfield}
                InputProps={{ disableUnderline: true, inputProps: { min: configJSON.minSliderValue, max: this.state.maxSliderValue - this.state.rankedValue[0]} }}
                value={Math.max(this.state.maxSliderValue - this.state.rankedValue[1], 1)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangeRankedValue(event, 1)}
                type="number"
              />
            </Box>

            <Box
              sx={{ width: "inherit", padding: "24px", position: "relative" }}
            >
              <CustomSlider
                dir="rtl"
                data-test-id="rangeslider"
                min={0}
                max={this.state.maxSliderValue - 1}
                value={this.state.rankedValue}
                onChange={this.handleChange}
                name="ranked"
                valueLabelDisplay="on"
                getAriaValueText={this.getRangeValue}
                valueLabelFormat={(value) => (
                  <div
                    style={{
                      color: "#1F497D",
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontWeight: 700,
                    }}
                  >
                    {this.state.maxSliderValue - value}{" "}
                  </div>
                )}
              />

              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" style={webStyle.minMaxText}>
                  Lowest
                </Typography>

                <Typography variant="h6" style={webStyle.minMaxText}>
                  Highest
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box borderBottom={"1px solid #9C9C9C"}>
            <Box style={{ padding: "24px" }}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6" style={webStyle.filterLable}>
                  Country
                </Typography>

                <Typography variant="h6" style={webStyle.clearText} onClick={() => this.handleClear("country")} data-test-id="clearCountry">
                  Clear
                </Typography>
              </Box>

                <Box style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                  <CountryAutocomplete
                    data-test-id="country-test"
                    style={{
                      backgroundColor: "var(--black-5, #1C1C1C0D)",
                      borderRadius: "8px",
                    }}
                    options={this.state.countriesList}
                    getOptionLabel={(option: unknown) => this.handleTwoCondition((option as InputValueType).label, '')}
                    value={''}
                    inputValue={this.state.selectedCountryToRemove}
                    onInputChange={(event, value) => this.handleCountryRemove(value)}
                    onChange={(event: React.ChangeEvent<{}>, value: unknown) => this.handleCountryChange(event as React.ChangeEvent<HTMLInputElement>, value as InputValueType)}
                    renderInput={(params) => (
                      <TextFieldAutocomplete
                        {...params}
                        data-test-id="countryTextField"
                        value={''}
                        variant="outlined"
                        onChange={this.countryListHandler}
                        placeholder="Search"
                        style={{ borderRadius: "8px" }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={searchImg} alt="search" />
                            </InputAdornment>
                          ),
                          style: {
                            paddingLeft: "8px",
                            paddingTop: "2px",
                            paddingRight: "8px",
                            paddingBottom: "2px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={text} alt="text" />
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                      />
                    )}
                  />
                </Box>
                  {
                    this.state.selectedCountries &&
                    this.state.selectedCountries.label &&
                    <Box data-test-id="seletedCountryList">
                      <FormGroup>
                        <CustomTalentCheckBoxComponent
                          data-test-id="countryCheckbox"
                          defaultChecked
                          title={this.state.selectedCountries.label}
                          onClick={() => this.handleClear("country")}
                        />
                      </FormGroup>
                    </Box>
                  }
              </Box>
          </Box>

          <Box borderBottom={"1px solid #9C9C9C"}>
            <Box style={{ padding: "24px" }}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6" style={webStyle.filterLable}>
                  City
                </Typography>

                <Typography variant="h6" style={webStyle.clearText} onClick={() => this.handleClear("city")} data-test-id="clearCity">
                  Clear
                </Typography>
              </Box>

              <Box style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                  <CountryAutocomplete
                    data-test-id="city-test"
                    style={{
                      backgroundColor: "var(--black-5, #1C1C1C0D)",
                      borderRadius: "8px",
                    }}
                    options={this.state.citiesList}
                    getOptionLabel={(option: unknown) => this.handleTwoCondition((option as InputValueType).label, '')}
                    value={''}
                    inputValue={this.state.selectedCityToRemove}
                    onInputChange={(event, value) => this.handleCityRemove(value)}
                    onChange={(event: React.ChangeEvent<{}>, value: unknown) => this.handleCityChange(event as React.ChangeEvent<HTMLInputElement>, value as InputValueType)}
                    renderInput={(params) => (
                      <TextFieldAutocomplete
                        {...params}
                        style={{ borderRadius: "8px" }}
                        data-test-id="skillsTextField"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={searchImg} alt="search" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={text} alt="text" />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        size="small"
                        value={''}
                        onChange={this.cityListHandler}
                        placeholder="Search"
                      />
                    )}
                  />
                </Box>
                  {
                    this.state.selectedCities &&
                    this.state.selectedCities.label &&
                    < Box data-test-id="selectedCityList" >
                    <FormGroup>
                      <CustomTalentCheckBoxComponent
                        data-test-id="cityCheckbox"
                        defaultChecked
                        title={this.state.selectedCities.label}
                        onClick={() => this.handleClear("city")}
                      />
                    </FormGroup>
                      </Box>
                }
            </Box>
          </Box>

          <Box borderBottom={"1px solid #9C9C9C"}>
            <Box style={{ padding: "24px" }}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6" style={webStyle.filterLable}>
                  Experience
                </Typography>

                <Typography variant="h6" style={webStyle.clearText} onClick={() => this.handleClear("experiences")} data-test-id="clearExperience">
                  Clear
                </Typography>
              </Box>

                  <Box style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                    <FormGroup>
                      <CustomTalentCheckBoxComponent
                        data-test-id="checkbox1"
                        title="0-2 Years"
                        checked={this.state.experiences["2years"].checked}
                        onChange={this.handleSetExperience}
                        name="2years"
                      />
                      <CustomTalentCheckBoxComponent
                        title="2-4 Years"
                        checked={this.state.experiences["4years"].checked}
                        name="4years"
                        onChange={this.handleSetExperience}
                      />
                      <CustomTalentCheckBoxComponent
                        title="4-6 Years"
                        checked={this.state.experiences["6years"].checked}
                        name="6years"
                        onChange={this.handleSetExperience}
                      />
                      <CustomTalentCheckBoxComponent
                        title="6-8 Years"
                        checked={this.state.experiences["8years"].checked}
                        name="8years"
                        onChange={this.handleSetExperience}
                      />
                      <CustomTalentCheckBoxComponent
                        title="8-10 Years"
                        checked={this.state.experiences["10years"].checked}
                        name="10years"
                        onChange={this.handleSetExperience}
                      />
                      <CustomTalentCheckBoxComponent
                        title="10+ Years"
                        checked={this.state.experiences["10+years"].checked}
                        name="10+years"
                        onChange={this.handleSetExperience}
                      />
                    </FormGroup>
                  </Box>
            </Box>
          </Box>

          <Box borderBottom={"1px solid #9C9C9C"}>
            <Box style={{ padding: "24px" }}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="h6" style={webStyle.filterLable}>
                  Skills
                </Typography>

                <Typography variant="h6" style={webStyle.clearText} onClick={() => this.handleClear("skills")} data-test-id="clearSkills">
                  Clear
                </Typography>
              </Box>

                  <Box style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                    <CountryAutocomplete
                      data-test-id="skillsTest"
                      style={{
                        backgroundColor: "var(--black-5, #1C1C1C0D)",
                        borderRadius: "8px",
                      }}
                      options={this.state.skills}
                      getOptionLabel={(option: unknown) => (option as string) ?? ''}
                      value={''}
                      inputValue={this.state.selectedSkillToRemove}
                      onInputChange={(event, value) => this.handleSkillRemove(value)}
                      onChange={(event, value: string | unknown) => this.handleSkillChange(value)}
                      renderInput={(params) => (
                        <TextFieldAutocomplete
                          {...params}
                          data-test-id="skillsTextFieldAutoComplete"
                          value={''}
                          size="small"
                          variant="outlined"
                          placeholder="Search"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={searchImg} alt="search" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <img src={text} alt="text" />
                              </InputAdornment>
                            ),
                            style: {
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                            },
                          }}
                          style={{ borderRadius: "8px" }}
                        />
                      )}
                    />
                  </Box>
                {
                  this.state.selectedSkills.length > 0 &&
                  this.state.selectedSkills.map((skill) => {
                    return (
                      <Box key={skill}>
                        <FormGroup>
                          <CustomTalentCheckBoxComponent
                            data-test-id="selectedSkills"
                            defaultChecked
                            title={skill}
                            onChange={() => this.handleRemoveSkill(skill)}
                          />
                        </FormGroup>
                      </Box>
                    )
                  })
                }
                </Box>
                <Box style={{position: "relative"}}>
                  {!this.state.isSubscribedElite && <Box
                    style={{
                      backgroundColor: "#FFFFFF",
                      textAlign: "center",
                      paddingBottom: "28px",
                      position: "absolute",
                      zIndex: 1,
                      opacity: 1,
                      background: "transparent",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Arial",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "22px",
                        padding: "28px 24px 11px 24px",
                        color: "#9C9C9C"
                      }}>
                      Unlock exclusive benefits like direct approach filter access with our elite plan subscription! Elevate your experience and enjoy premium features. {this.renderBtnLabel()} for more!
                    </Typography>
                    <Button 
                    data-test-id="subscribeBtn"
                    style={{
                      width: "138px",
                      height: "42px",
                      padding: "10px 16px 10px 16px",
                      borderRadius: "8px",
                      backgroundColor: "#6D97C1",
                      textTransform: "none",
                      color: "#FFFFFF",
                      cursor: "pointer"
                    }}
                    onClick={(event) => this.handleSubscribeClick(event)}
                    >
                      {this.renderBtnLabel()}
                    </Button>
                  </Box>}

                  <Box style={{ padding: "24px", filter: this.handleBlurEffect() , opacity: this.state.isSubscribedElite ? "1" : "0.3" }} borderBottom={"1px solid #9C9C9C"}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant="h6" style={webStyle.filterLable}>
                        Open for Direct Approach
                      </Typography>

                      <Typography variant="h6" style={webStyle.clearText} onClick={() => this.handleClear("directApproach")} data-test-id="clearDirectApproach">
                        Clear
                      </Typography>
                    </Box>
                    <Box style={{ paddingTop: "11px", paddingBottom: "11px" }}>
                      <FormGroup>
                        <CustomTalentCheckBoxComponent data-test-id="selectedDirectApproachYes" checked={this.state.directApproach === "yes"} onChange={this.handleDirectApproach} title="Yes" name="yes" />
                        <CustomTalentCheckBoxComponent data-test-id="selectedDirectApproachNo" checked={this.state.directApproach === "no"} onChange={this.handleDirectApproach} title="No" name="no" />
                      </FormGroup>
                    </Box>
                  </Box>
                </Box>
          </Box>
          </> 
          }
        </CustomScrollBarBox>
        </Box>

        <Box
          style={{
            padding: "16px",
            backgroundColor: "#F3F4F8",
            height: "calc(100vh - 64px)",
          }}
        >
          {this.state.candidateProfileShow ? (
            <Box style={webStyle.profileContainer}>
              <Box>
                <img
                  src={imgButtonBack}
                  alt="Button back"
                  data-test-id={"backButton"}
                  onClick={(event) => this.handleCandidates(event, false)}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              {this.renderDashboard()}
            </Box>
          ) : (
            <Box style={webStyle.rightContainer}>
              <Tabs
                data-test-id="tabs"
                value={this.state.tabsValue}
                onChange={this.handleTabChange}
                variant="fullWidth"
                TabIndicatorProps={{
                  style: { backgroundColor: "#6D97C1" },
                }}
              >
                <Tab
                  label={
                    <span
                      style={
                        this.state.tabsValue === 0
                          ? webStyle.tabsActiveLable
                          : webStyle.tabsDeactiveLable
                      }
                    >
                      Talent Pool
                    </span>
                  }
                  style={{
                    flex: 1,
                    textAlign: "center",
                    margin: "auto",
                    backgroundColor: this.handleBackgroundColor(this.state.tabsValue === 0)
                  }}
                />

                <Tab
                  data-test-id="pipelineTab"
                  label={
                    <span
                      style={
                        this.state.tabsValue === 1
                          ? webStyle.tabsActiveLable
                          : webStyle.tabsDeactiveLable
                      }
                    >
                      Talent Pipeline
                    </span>
                  }
                  style={{
                    flex: 1,
                    textAlign: "center",
                    margin: "auto",
                    backgroundColor: this.handleBackgroundColor(this.state.tabsValue === 1)
                  }}
                />
              </Tabs>
              {this.renderContent()}
                <Grid container style={{ display: "flex", width: "100%", justifyContent: "center" }} id="loader-container">
                  {this.state.isInfiniteLoading && <CircularProgress style={{ color: '#1F497D' }} />}
                </Grid>
            </Box>
          )}

        </Box>
        <ToastContainer/>
      </PageContainerBox>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  drawer: {
    width: 421,
    flexShrink: 0,
  },

  filterLable: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#17365D",
    width: "inherit",
  },

  filterBox: {
    borderRadius: "10px 0px 0px 0px",
    borderBottom: "1px solid #9C9C9C",
    borderBottomWidth: "1px",
    justifyContent: " space-between",
    padding: "24px",
  },

  rankedLable: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#334155",
  },
  rankedBox: {
    borderBottom: "1px solid #9C9C9C",
  },
  textfield: {
    width: "70px",
    height: "32px",
    gap: "8px",
    borderRadius: "8px",
    borderWidth: "1px",
    border: "1px solid #9C9C9C",
    backgroundColor: "#F4F4F4",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#1F497D",
  },
  rangedLable: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#17365D",
    width: "139px",
  },
  clearText: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
    width: "inherit",
    cursor: "pointer",
  },
  checkBoxLabels: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "var(--Dark-text, #17365D)",
    width: "inherit",
  },

  addLocationText: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "var(--Neutrals-Cool-gray-700_icon, #334155)",
    width: "inherit",
  },

  subscribeText: {
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#9C9C9C",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  minMaxText: {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "var(--Dark-text, #17365D)",
  },
  toText: {
    color: "var(--Dark-text, #17365D)",
    fontFamily: "Arial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
  rightContainer: {
    width: "100%",
    top: "101px",
    left: "461px",
    gap: "26px",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",
    maxWidth: "956px",
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    marginBottom: "80px",
    paddingBottom: "50px"
  },

  profileContainer: {
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",
    padding: "27px",
    marginBottom: "80px"
  },

  tabsActiveLable: {
    color: "var(--Dark-text, #17365D)",
    fontFamily: "Arial",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    padding: "16px",
    textTransform: "none" as "none",
  },
  tabsDeactiveLable: {
    color: "var(--Light-text, #9C9C9C)",
    fontFamily: "Arial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    padding: "16px",
    textTransform: "none" as "none",
  },
  condidateBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "20px",
    paddingRight: "20px",
    alignItems: "center",
  },

  noTalentBottomBox: {
    maxWidth: "448px",
    marginTop: "24px",
    textAlign: "center" as "center"
  },

  noTalentHeading: {
    fontFamily: "Arial",
    fontWeight: 700,
    color: "#17365D",
    fontSize: "24px",
  },

  noTalentText: {
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "16px",
    color: "#9C9C9C",
    marginTop: "8px"
  },

  noTalentButton: {
    width: "165px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    backgroundColor: "#1F497D",
    textTransform: "none" as "none",
    color: "#FFFFFF",
    cursor: "pointer",
    fontFamily: "Arial",
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "36px"
  },

  candidateCountText: {
    color: "var(--Basic-Black, #000)",
    fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
    fontFamily: "Arial",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
  },
  cardContanier: {
    borderRadius: "16px",
    border: "1px solid var(--Primary-color, #1F497D)",
    background: "var(--Basic-White, #FFF)",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  },
  countryContanier: {
    borderRadius: "16px",
    background: "var(--Basic-White, #FFF)",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  },
  candidtaeNameText: {
    color: "var(--Neutrals-Cool-gray-900, #0F172A)",
    fontFamily: "Arial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textDecorationLine: "underline",
    textUnderlineOffset: "2px",
    cursor: 'pointer'
  },

  canddateNameProfile: {
    color: "var(--Dark-text, #17365D)",
    fontFamily: "Arial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
    textDecorationLine: "underline",
    textUnderlineOffset: "2px",
  },

  resumeText: {
    color: "#17365D",
    fontFamily: "Arial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
  },

  resumePdfText: {
    color: "#34383F",
    fontFamily: "Arial",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },

  skillsText: {
    color: "var(--Dark-text, #17365D)",
    fontFamily: "Arial",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    marginLeft: "10px",
  },

  candidtaeDiscription: {
    color: "var(--Light-text, #9C9C9C)",
    fontFamily: "Arial",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  potentialScoreText: {
    color: "var(--Primary-light, #6D97C1)",
    fontFamily: "Arial",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  appliedText: {
    color: "var(--Light-text, #9C9C9C)",
    fontFamily: "Arial",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },
  cityList: {
    color: "var(--Primary-color, #1F497D)",
    fontFamily: "Arial",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    cursor: "pointer",
  },
  skillsChip: {
    height: "24px",
    borderRadius: "4px",
    padding: "6px 8px 6px 8px",
    backgroundColor: "#EEECE1",
    color: "#1F497D",
    marginRight: "13px",
    fontSize: "12px",
    fontFamily: "Arial",
    marginBottom: "13px",
  },
  item: {
    background: "#ffffff",
    borderRadius: "20px",
    padding: "20px 0",
    minHeight: "25px",
  },
  personalDetailsTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "fit-content",
    marginBottom: "15px",
  },

  blockHeading: {
    fontFamily: "Arial",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#17365D",
  },
  personalDetailsPercentage: {
    fontFamily: "Arial",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
    paragraph: "8px",
    color: "#17365D",
  },
  tableWrapper: {
    maxHeight: "490px",
    overflow: "auto",
  },

  tableItem: {
    border: "none",
    width: "16.67%",
    color: "#1F497D",
    fontFamily: "Arial",
    fontSize: "12px",
  },
  experienceTitle: {
    color: "#1F497D",
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
};
// Customizable Area End
