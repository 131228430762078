import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from "@material-ui/styles";
import {
    Box  
  } from "@material-ui/core";

interface DataItem {
    icon: string;
    name: string;
    key: "home" | "popular" | "following" | "saved"
}

interface Categorieslist {
    type: "string",
    id: number | string,
    attributes: {
    name: string,
      id: number | string,
      icon: string | null,
      menuItems: {
        type: string;
        id: string;
        attributes: {
            name: string;
            id: number;
        };
      }[]
    }
  }

interface MyProps {
    data: DataItem[];
    categoriesList: {
        [category: string]: {
            icon: string,
            topics: string[]
        };
    };
    selectedSubCategory: string | Number;
    selectedPostType: "home" | "popular" | "following" | "saved";
    setOpenedCategory: (value: string) => void;
    handlePostList: (value: "home" | "popular" | "following" | "saved") => void
}

const useStyles = makeStyles(() => ({
    root: {
        background: 'white',
        borderRadius: 20,
        padding: '20px',
        overflowY: 'auto',
        overflowX: 'clip',
        "@media (min-width: 800px)": {
            position: "sticky",
            top: "5px",
        },
        "& .MuiAccordionSummary-root": {
            maxHeight: "37px !important",
            minHeight: "auto !important",
        },
        "& .Mui-expanded": {
            marginTop: "0px !important",
            marginBottom: "0px !important",
            minHeight: "auto !important",
        },
    },
    menuText: {
        fontSize: '14px',
        fontWeight: 'lighter',
        color: '#17365D',
        paddingLeft: '10px',
        cursor: "pointer",
        fontFamily: 'Arial'
    },
    subTopics: {
        paddingLeft: '2px'
    },
    list: {
        margin: '10px 0px',
        padding: '6px 0px 6px 10px'
    },
    listSec: {
        padding: '4px 0px 4px 8px',
        marginBottom: "6px"
    },
    activeList: {
        margin: '10px 0px',
        background: '#F3F4F8',
        borderRadius: 20,
        padding: '6px 0px 6px 10px'
    },
    activeListSec: {
        "background": "#F3F4F8",
        "borderRadius": "16px",
        "height": "24px",
        "display": "flex",
        "alignItems": "center",
        "paddingLeft": "8px",
        "marginBottom": "6px"
    },
    icons: {
        height: '16px',
        width: '16px',
    },
    heading: {
        marginTop: '10px',
        marginLeft: '12px',
        color: '#17365D',
        fontFamily: 'Arial',
        fontSize: "12px"
    },
    accordian: {
        border: 'none',
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 0
        },
        '& .MuiAccordionSummary-content': {
            margin: '0px !important',
            paddingLeft: '10px',
            display: "flex",
            alignItems: "center"
        },
        '& .MuiAccordionSummary-root': {
            padding: '0px !important',
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px !important',
            display: 'block'
        }
    }
}));

const CustomScrollBox = styled(Box)({
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '87px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '57px',
      background: 'var(--Base-Grey-5, #D9D9D9)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6C96C1', // Color of the scrollbar thumb
      borderRadius: '57px', // Border radius of the scrollbar thumb
      '&:hover': {
        background: '#3489e0', // Color of the scrollbar thumb on hover
      },
    },
  });

const LandingLeftSide: React.FC<MyProps> = (props) => {
    const classes = useStyles();
    return (
        <CustomScrollBox className={classes.root}>
            {props.data.map((item: DataItem, index: number) => (
                <Grid container className={props.selectedPostType === item.key ? classes.activeList : classes.list} key={index}>
                    <Grid item><img src={item.icon} alt={item.name} className={classes.icons} /></Grid>
                    <Grid item>
                        <Typography className={classes.menuText} onClick={() => props.handlePostList(item.key)}>{item.name}</Typography>
                    </Grid>
                </Grid>
            ))}
            <Divider />
            <Typography className={classes.heading}>TOPICS</Typography>
            {Object.keys(props.categoriesList).map((item: string, index: number) => {
                return <Accordion className={classes.accordian} id={"accordion-main-with-change" + index} key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {props.categoriesList[item].icon && <img src={props.categoriesList[item].icon} className={classes.icons}/> }
                        <Typography className={classes.menuText}>{item}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {props.categoriesList[item].topics.map((subCategory) => {
                            return (
                                <div 
                                className={props.selectedSubCategory === subCategory ? classes.activeListSec : classes.listSec} 
                                key={subCategory} 
                                onClick={() => props.setOpenedCategory(subCategory)}
                                >
                                    <Typography id="cat-item" className={`${classes.menuText} ${classes.subTopics}`}>{subCategory}</Typography>
                                </div>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            })}
        </CustomScrollBox>
    )
}

export default LandingLeftSide;
