import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
import { ICountry, Country, City, ICity } from 'country-state-city'
import { debounce } from 'lodash';
import { callWithDelay, getLocation, getLoginToken, getUserId } from "../../../../packages/components/src/Utils.web";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { CountryData } from 'react-phone-input-2';
import { FormikTouched } from "formik";

interface FormikFormValues {
    "name": string,
    "phone_number": string,
    "country_field": string,
    "city_field": string,
    "workemail": string,
    "personalemail": string,
    "bio": string,
    "full_name": string,
    "location": string,
    "current_title": string,
    "full_phone_number": string,
    "email": string,
    "experience": string,
    "salary": {
        amount: string,
        currency: string
    },
    "future_self": string,
    "current_company": string,
    "company_indusry": string,
    "photo": any
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loader:boolean;
    progress:number;
    coords: {
        latitude: number,
        longitude: number,
      };
    remainingTime:number;
    token: string;
    selectedCompanyName: string,
    open: boolean,
    isFirstTimeLogin: boolean,
    profileBlob: string | ArrayBuffer | null;
    isProfileUploaded: boolean;
    countries: { value: string; label: string }[];
    setProfile: boolean;
    phoneNumber: string,
    phoneInputFormat: any,
    companyList: { value: string, label: string }[]
    initialValues: FormikFormValues,
    groupContext: string,
    cities: { value: string; label: string; }[]
    isDivVisible: boolean,
    experienceOpen: boolean,
    experienceArr: {label: string, value: string}[],
    isEmailVerified: boolean,
    resend: boolean,
    otpError: boolean,
    otpValue: string,
    emailToDisplay: string,
    initialEmail: string,
    showVerifiyEmail: boolean,
    verifyLoader: boolean,
    timer: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

type FormValues = {
    name: string;
    phone_number: string;
    country_field: string;
    city_field: string;
    workemail: string;
    personalemail: string;
    bio: string;
    full_name: string;
    location: string;
    current_title: string;
    full_phone_number: string;
    email: string;
    experience: string;
    salary: {
        amount: string,
        currency: string
    };
    future_self: string;
    current_company: string;
    company_indusry: string;
    photo: any;
}
export default class UserProfileEditController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    updateUserDetailsApiId: Message | string = ""
    getUserProfileDataApiId: Message | string = ""
    companyListApiId: Message | string = ""
    currentLocation: string = ""
    reSendOtpApiIdOne: Message | string = ""
    sendOtpApiIdOne: Message | string = ""
    verifyOtpApiId: Message | string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.SessionResponseToken),
            getName(MessageEnum.SessionSaveMessage)
        ];

        this.state = {
            verifyLoader: false,
            timer: false,
            showVerifiyEmail: false,
            initialEmail: "",
            emailToDisplay: "",
            otpValue: "",
            otpError: false,
            resend: false,
            isEmailVerified: true,
            loader:false,
            progress:0,
            remainingTime:0,
            token: '',
            coords: {
                latitude: 0,
                longitude: 0,
              },
            isFirstTimeLogin: false,
            selectedCompanyName: "",
            open: false,
            phoneNumber: "",
            phoneInputFormat: {},
            initialValues: {
                name: "",
                phone_number: "",
                country_field: "",
                city_field: "",
                workemail: "",
                personalemail: "",
                bio: "",
                full_name: "",
                location: "",
                current_title: "",
                full_phone_number: "",
                email: "",
                experience: "0-2 Years",
                salary: {
                    amount: '',
                    currency: ''
                },
                future_self: "",
                current_company: "",
                company_indusry: "",
                photo: "",
            },
            groupContext: "",
            cities: [],
            countries: [],
            companyList: [{ value: '', label: '' }],
            setProfile: true,
            profileBlob: "",
            isProfileUploaded: false,
            isDivVisible: false,
            experienceOpen: false,
            experienceArr: [
                {label: '0-2 Years', value: '0-2 Years'},
                {label: '2-4 Years', value: '2-4 Years'},
                {label: '4-6 Years', value: '4-6 Years'},
                {label: '6-8 Years', value: '6-8 Years'},
                {label: '8-10 Years', value: '8-10 Years'},
                {label: '10+ Years', value: '10+ Years'},
            ]
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        
        this.getUserdata();
        document.querySelector("#company_name")?.addEventListener('keypress', (event:any) => {
            if(event.keyCode === 13) {
                event.preventDefault();
            }
        })
        const setProfile = await getStorageData("setProfile")
        const isFirstTimeLogin = JSON.parse(localStorage.getItem("setProfile") || "")
        this.setState({isFirstTimeLogin})
        !isFirstTimeLogin && this.getCurrentLocation().finally(() => {
            this.GetUserCurrentLoc()
          })
        this.setState({
            setProfile: setProfile === 'true' ? true : false,
            countries: Country.getAllCountries().map((country: ICountry) => ({
                value: country.name,
                label: country.name,
            }))
        })
    }

    setPhoneInput = (text:string, country:any, setFieldValue: any) => {
        this.setState({phoneNumber: text, phoneInputFormat: country.format})
        setFieldValue('full_phone_number', text)
    }

    handleValidPhone = () => {
        const { phoneNumber, phoneInputFormat } = this.state;
        let phoneLengthCount = 0;
        for(let char of phoneInputFormat) {
          if(char ===".")
            phoneLengthCount++
        }
        if(phoneNumber.length === phoneLengthCount)
            return true;
        else
        return false
      }
 

    checkForEmailValueOne = (email: string, error?: string) =>  {
        if (email && !error) {
            this.sendOtpCallOne(email)
            this.setState({
                emailToDisplay: email,
            })
        }
    }

    handleOtpResponseOne = (responseJson : { message?: string, error?: string }) => {
        if("message" in responseJson) {
            toast.success(responseJson.message);
            this.setState({
                showVerifiyEmail: true
            })
        } else if('error' in responseJson) {
            toast.error(responseJson.error);
        }
    }

    handleResendOtpResponseOne = (responseJson : { message?: string, error?: string }) => {
        if("message" in responseJson) {
            toast.success(responseJson.message);
        } else if('error' in responseJson) {
            toast.error(responseJson.error);
        }
    }

    handleVerifyOtpOne = (responseJson: { verified: string, message: string }) => {
        if (responseJson?.verified) {
            this.setState({
                showVerifiyEmail: false,
                otpError: false,
                initialEmail: this.state.emailToDisplay,
                verifyLoader: false,
                otpValue: '',
            });
            toast.success(responseJson.message)
        } else {
            this.setState({
                otpError: true, verifyLoader: false, otpValue: ''
            });
        }
    }

    sendOtpCallOne = async (email: string, resend?: boolean) => {
        if (resend) {
            this.setState({
                resend: resend,
                otpError: false,
                otpValue: ''
            });
        }

        const authToken = await getStorageData("authToken")

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };

        let userData = { email };

        const sendOtpMsgOfEmailChange = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );

        if (resend) {
            this.reSendOtpApiIdOne = sendOtpMsgOfEmailChange.messageId;
        } else {
            this.sendOtpApiIdOne = sendOtpMsgOfEmailChange.messageId;
        }

        sendOtpMsgOfEmailChange.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendOtpApiEndpoint
        );

        sendOtpMsgOfEmailChange.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        sendOtpMsgOfEmailChange.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );

        sendOtpMsgOfEmailChange.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(sendOtpMsgOfEmailChange.id, sendOtpMsgOfEmailChange);
    }

    handleCloseClickOne=()=>{
        this.setState({
            otpValue: '',
            showVerifiyEmail: !this.state.showVerifiyEmail,
            otpError: false
        })
    }

    handleChangeOTPOne = (value: string) => {
        this.setState({ otpValue: value });
    };
    
    verifyOtpCallOne = async () => {
        this.setState({
            verifyLoader: true
        })
        
        const authToken = await getStorageData("authToken")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        let userData = { 
            otp: this.state.otpValue, 
            email: this.state.emailToDisplay 
        };
        const verifyOTPmsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.verifyOtpApiId = verifyOTPmsg.messageId;
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.verifyEmailApiEndpoint);
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(userData));
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
        runEngine.sendMessage(verifyOTPmsg.id, verifyOTPmsg);
    }

    handleOTPTimerCallOne = (val : boolean) => {
        this.setState({
          timer: val,
        });
    };

    replaceNullWithEmptyString = (obj: FormValues & {city: string, country: string, currency: string, salary: string}): FormValues => {
        const newObj: FormValues = {
            name: obj?.name ?? '',
            phone_number: obj?.phone_number,
            country_field: obj?.country ?? '',
            city_field: obj?.city ?? '',
            workemail: obj?.workemail,
            personalemail: obj?.personalemail,
            bio: obj?.bio,
            full_name: obj?.full_name ?? '',
            email: obj?.email,
            location: obj?.location ?? "",
            current_title: obj?.current_title ?? "",
            full_phone_number: obj?.full_phone_number ?? "",
            experience: obj?.experience ?? "0-2 Years",
            salary: obj?.salary ? {
                amount: obj.salary,
                currency: obj.currency
            } : {
                amount: "",
                currency: ""
            },
            future_self: obj?.future_self ?? "",
            current_company: obj?.current_company ?? "",
            company_indusry: obj?.company_indusry ?? "",
            photo: obj?.photo ?? ""
        };
        if(obj.full_phone_number !== "") {
            this.setState({phoneInputFormat: newObj.full_phone_number.substring(0,1)})
            this.setState({phoneNumber: newObj.full_phone_number})
        }
        if(obj.email !== "") {
            this.setState({
                isEmailVerified: true,
                initialEmail: obj.email
            })
        }
        return newObj;
    }

    handlegetUserProfileDataApi = (responseJson: any) => {
        let res = responseJson?.data?.attributes
        delete res?.id;

        let data = this.replaceNullWithEmptyString(res)

        const country = Country.getAllCountries().find((country: ICountry) => country.name === data.country_field);

        this.setState({
            groupContext: res.selected_group_context,
            selectedCompanyName: res.current_company,
            initialValues: this.replaceNullWithEmptyString(res),
            cities: country ? (City.getCitiesOfCountry((Country.getAllCountries().find((country: ICountry) => country.name === data.country_field) as ICountry).isoCode || "0")?.map((city: ICity) => ({
                value: city.name,
                label: city.name,
            })) as { value: string, label: string }[]) : []
        })
    }
    
    isPhoneValid = ({ phone, country }: {
        phone: string;
        country: CountryData | {};
    }) => {
        const { countryCode: countryCodePhoneInput, dialCode } = country as CountryData;
        if (!countryCodePhoneInput || !dialCode) return true;
        const countryCode = countryCodePhoneInput.toUpperCase() as CountryCode;
        const phoneWithoutDialCode = phone.replace(dialCode, '');
        const phoneNumber = parsePhoneNumber(phoneWithoutDialCode, countryCode);
        return phoneNumber?.isValid();
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                this.setGroupContextValueFromHeader(responseJson)
                if (webApiRequestCallId === this.updateUserDetailsApiId) {
                    if (responseJson?.data) {
                        toast.success("Profile Updated successfully");
                        this.props.navigation.goBack();
                    }
                } else if (webApiRequestCallId === this.getUserProfileDataApiId) {
                    this.handlegetUserProfileDataApi(responseJson);
                } else if (webApiRequestCallId === this.companyListApiId) {
                    const companyList = responseJson?.company_listing?.map((country: any) => ({
                        value: country.name,
                        label: country.name,
                    }));
                    this.setState({ companyList })
                }

                switch (webApiRequestCallId) {
                    case this.sendOtpApiIdOne:
                        return this.handleOtpResponseOne(responseJson)
                    case this.reSendOtpApiIdOne:
                        return this.handleResendOtpResponseOne(responseJson)
                    case this.verifyOtpApiId:
                        return this.handleVerifyOtpOne(responseJson)
                    default:
                        return null
                }

            }
        }
        this.handleResposnseForCurrentLocation(from, message);
        // Customizable Area End
    }

    setGroupContextValueFromHeader = (responseJson: { data: { type: string, attributes: { selected_group_context: string} }}) => {
        if(responseJson.data && responseJson.data.type === "profile") {
            this.setState({
                groupContext: responseJson.data.attributes.selected_group_context || ''
            })
        }
    }

    handleResposnseForCurrentLocation = (from: string, message: Message) => {
        const webApiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (webApiRequestCallId === this.currentLocation) {
            if(responseJson.error) {
                toast.error(responseJson.error);
            }
            if(!responseJson.error) {
                const country = Country.getAllCountries().find((country: ICountry) => country.name === responseJson.country);
                this.setState({
                    cities: country ? (City.getCitiesOfCountry((Country.getAllCountries().find((country: ICountry) => country.name === responseJson.country) as ICountry).isoCode || "0")?.map((city: ICity) => ({
                        value: city.name,
                        label: city.name,
                    })) as { value: string, label: string }[]) : []
                })
                this.setState({initialValues: {...this.state.initialValues, city_field: responseJson.city, country_field: responseJson.country}})
            }
        }
    }
    handleExpClose = () => {
        this.setState({experienceOpen: false})
    }
    handleExpToggle = () => {
        this.setState({experienceOpen: !this.state.experienceOpen})
    }
    handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement> | null,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldError: (field: string, message: string | undefined) => void,
        setTouched: (touched: FormikTouched<FormikFormValues>) => void,
        touched: FormikTouched<FormikFormValues>
    ) => {
        callWithDelay(
            setTouched, 
            {...touched, "photo": true}
        )
        
        if(event && event.currentTarget.files && event.currentTarget.files[0].type.includes("svg")){
            callWithDelay(setFieldError, "photo", "Please upload .jpg, OR .png OR .jpeg file")
            return;
        }
        if (event && event.currentTarget.files && !event.currentTarget.files[0].type.includes("image")) {
            callWithDelay(setFieldError, "photo", "Please upload .jpg, OR .png OR .jpeg file")
            return;
        } else if (event && event.currentTarget.files && event.currentTarget.files[0].size > 10 * 1024 * 1024) {
            callWithDelay(setFieldError, "photo", "Please upload file less than 10mb")
            return;
        }
        if (event && event.currentTarget.files && event.currentTarget.files.length > 0) {
            setFieldValue('photo', event.currentTarget.files[0]);
            this.setState({
                isProfileUploaded: true
            });
            const reader = new FileReader();
            let x = () => {
                this.setState({
                    profileBlob: reader.result
                })
            };
            reader.onloadend = x
            reader.readAsDataURL(event.currentTarget.files[0]);
            x();
        }
    }

    handleCancel = () => {
        this.props.navigation.goBack();
    }
    getCurrentLocation = async (): Promise<void> => {
        return getLocation()
          .then((position) => {
            this.setState(
              {
                coords: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                },
              }
            );
          }).catch(() => {
            this.setState({
              coords: { latitude: 0, longitude: 0 },
            });
          })
      };

    handleProfileState = () => {
        this.setState({
            isProfileUploaded: false
        });
    }

    handleConditionRender = (condition : boolean | string | undefined, truePart: string | any, falsePart:string | any) => {
        return condition ? truePart : falsePart
    }

    handleSelectCompany = (name: string) => {
        this.setState({
            selectedCompanyName: name,
            open: false
        });
    };
 
    storeGroupContextApiCandidate = async(groupContext: string) => {
        
        const bodyData = {
            profile: {
              "group_context": groupContext
            }
        }

        if(this.state.groupContext){
            return;
        }

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
          };


      
        const groupContextMsgForCandidate = new Message(getName(MessageEnum.RestAPIRequestMessage));
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.selectedGroupContextApi}?token=${await getStorageData("authToken")}`);
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        runEngine.sendMessage(groupContextMsgForCandidate.id, groupContextMsgForCandidate);
    }

    GetUserCurrentLoc = async() => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };
        const getCurrentLocation = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.currentLocation = getCurrentLocation.messageId;
        getCurrentLocation.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.currentlocationEndPoint}?latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
        );
        getCurrentLocation.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCurrentLocation.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getCurrentLocation.id, getCurrentLocation);
    }

    handleCountryChange = (value: { value: string; label: string; }[]) => {
        this.setState({
            cities: value
        })
    }

    handleInputChangeDebounced = debounce(async (value: string) => {
        this.setState({open: true})

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };
        let userData = { search_name: value || '' };



        const updateUserDetails = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.companyListApiId = updateUserDetails.messageId;

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CompanyListApiEndPoint}`
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
    }, 200)


    companyListHandlers = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.handleInputChangeDebounced(value);
        }
    };

    storeGroupContext = async (groupContext: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
          };

        const bodyData = {
            profile: {
              "group_context": groupContext
            }
        }
      
        const groupContextMsgForCandidate = new Message(getName(MessageEnum.RestAPIRequestMessage));
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.selectedGroupContextApi}?token=${await getStorageData("authToken")}`);
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        groupContextMsgForCandidate.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
        runEngine.sendMessage(groupContextMsgForCandidate.id, groupContextMsgForCandidate);
    }

    handleGroupContextValue = async (fullName: string, title: string, company: string) => {
        if (this.state.groupContext === this.state.initialValues.full_name && fullName !== this.state.groupContext) {
            this.storeGroupContext(fullName);
        }
        if (this.state.groupContext === `as ${this.state.initialValues.current_title}` && `as ${title}` !== this.state.groupContext) {
            this.storeGroupContext(`as ${title}`);
        }
        if ((this.state.groupContext === `Work at ${this.state.initialValues.current_company}`) && (`Work at ${company}` !== this.state.groupContext)) {
            this.storeGroupContext(`Work at ${company}`);
        }
    }

    handleSubmitForm = async(data: FormValues) => {
        const authToken = getLoginToken();
        const startTime = Date.now();
        this.setState({ loader: true })
        let id = getUserId();
        let userData = { ...data };
        if(this.state.initialEmail !== userData.email){
            toast.error("Please verify email to continue")
            return;
        }
        await this.storeGroupContextApiCandidate(userData?.full_name);
        await this.handleGroupContextValue(userData.full_name, userData.current_title, this.state.selectedCompanyName)
        const formData = new FormData();
        formData.append("profile[api_name]", "candidate_update");
        formData.append("profile[full_name]", userData?.full_name);
        formData.append("profile[current_title]", userData?.current_title || "");
        formData.append("profile[email]", userData?.email || "");
        formData.append("profile[city]", userData?.city_field || "");
        formData.append("profile[country]", userData?.country_field);
        formData.append("profile[full_phone_number]", userData?.full_phone_number || "");
        formData.append("profile[experience]", userData?.experience || "");
        formData.append("profile[future_self]", userData?.future_self || "");
        formData.append("profile[salary]", userData?.salary?.amount || "");
        formData.append("profile[current_company]", this.state.selectedCompanyName || "");
        formData.append("profile[open_for_direct_approach]", "");
        formData.append("profile[bio]", userData?.bio || "");
        formData.append("profile[company_indusry]", userData?.company_indusry || "");
        formData.append("profile[currency]", userData.salary.currency || "");
        if (userData?.photo instanceof File) {
            formData.append("profile[photo]", userData.photo);
        }
        

        const config: AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const { loaded, total } = progressEvent;
                if (total) { const percentCompleted = Math.round((loaded * 100) / total);
                    const elapsedTime = (Date.now() - startTime) / 1000;
                    const uploadSpeed = loaded / elapsedTime;
                    const remainingTime = (total - loaded) / uploadSpeed;
                    this.setState({ progress: percentCompleted, remainingTime: remainingTime })
                }
            }
        };

        axios.put(`${configJSON.baseURL.baseURL}/${configJSON.updateUserDetailsEndPoint}/:${id}`, formData, config)
            .then(() => {
                setStorageData("setProfile","true");
                toast.success("Profile Updated successfully");
                this.props.navigation.goBack();
                this.setState({ loader:false,progress:0,remainingTime:0})
            })
            .catch(() => {
            });
    }
    getUserdata = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": getLoginToken()
        };

        const updateUserDetails = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserProfileDataApiId = updateUserDetails.messageId;

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}`
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        updateUserDetails.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );
        runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
    }

    // Customizable Area End
}
