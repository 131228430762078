import React, { Component } from 'react';
import { IconButton, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

interface ImageOrVideoItem {
    id: number | string;
    url: string;
}

interface CarouselProps {
    items: ImageOrVideoItem[];
    objectFit?: boolean;
    needBorderRadius?:boolean;
}

interface StateC {
    currentIdx: number,
    open: boolean
}

class SliderComponent extends Component<CarouselProps> {
    state: StateC = {
        currentIdx: 0,
        open: false
    };

    handleNext = () => {
        this.setState({ currentIdx: (this.state.currentIdx + 1) % this.props.items.length });
    };

    handleBack = () => {
        this.setState((prevState: StateC) => ({ currentIdx: (prevState.currentIdx - 1 + this.props.items.length) % this.props.items.length }));
    };

    toggleModal = () => {
        this.setState((prevState: StateC) => ({ open: !prevState.open }));
    };

    renderItem = (item: ImageOrVideoItem) => {
        return <img src={item?.url} style={{ width: '100%', height: '100%', objectFit: this.props.objectFit ? 'cover' : undefined, borderRadius: this.props.needBorderRadius ? "8px" : "0px" }}  />;
    };

    render() {
        const { items = [] } = this.props;
        const { currentIdx, open } = this.state;
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: "relative", width: "100%", height: "100%" }}>
                    {
                        items.length > 1 && <IconButton onClick={this.handleBack} style={{ position: "absolute", left: "5px", top: "45%" }}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    }
                    {
                        items.length > 0 && <div onClick={this.toggleModal} style={{ cursor: 'pointer', width: '100%', height: '100%' }} id="image-container">
                            {this.renderItem(items[currentIdx])}
                        </div>
                    }
                    {
                        items.length > 1 && <IconButton onClick={this.handleNext} style={{ position: "absolute", right: "5px", top: "45%" }}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    }
                </div>
                <Dialog open={open} onClose={this.toggleModal} maxWidth="lg" fullWidth>
                    <IconButton onClick={this.toggleModal} style={{ position: 'absolute', right: 0, top: 0 }}>
                        <CloseIcon />
                    </IconButton>
                    {this.renderItem(items[currentIdx])}
                </Dialog>
            </>
        );
    }
}

export default SliderComponent;
