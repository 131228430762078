import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface PercentileApexChartProps {
  data: number[];
  percentage: string;
}

const PercentileApexChart: React.FC<PercentileApexChartProps> = ({ data, percentage }) => {
  const options: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1.5,
      colors: ['#17365D'], 
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: '#A0BAD4',
            opacity: 0.7,
          },
          {
            offset: 100,
            color: '#0E4FA0', 
            opacity: 0,
          },
        ],
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      min: 0,
      max: 100,
    },
    tooltip: {
      enabled: false, 
    },
    grid: {
      show: false, 
    },
    markers: {
      size: 0,
    },
  };

  const series = [
    {
      name: 'Percentile',
      data: data, // Pass the data as a prop
    },
  ];

  return (
    <div style={{ position: 'relative', width: '58px', height: '29px' }}>
      <ReactApexChart options={options} series={series} type="area" height={29} />
      <div style={{
        position: 'absolute',
        top: '-10px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '10px',
        color: '#1F497D',
        fontWeight: 'bold',
      }}>
        {percentage}
      </div>
    </div>
  );
};

export default PercentileApexChart;
