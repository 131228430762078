import React from "react";
// Customizable Area Start
import { Helmet } from 'react-helmet';
import { Box, Button, Grid, Modal, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import CustomSelect from "./components/CustomSelect";
import SearchField from "./components/SearchField";
import { locationicon, searchicon } from "./assets";
import CatalogueController1, { FormValues, configJSON } from "./CatalogueController1";
import TabsComponent from "./components/TabsComponent";
import CustomLocationPopup from "./components/CustomLocationPopup";
import CustomeSelectComponent from "../../../components/src/CustomeSelectComponent";
import CreateJobSuccessModal from "./components/CreateJobSuccessModal";
import { ToastContainer, toast } from "react-toastify";
import { Toaster } from "react-hot-toast";
import AutoCompleteSeacrh from "../../../../packages/components/src/AutoCompleteSeacrh.web";
import { isAnyFilterSelected, META_TAGS } from '../../../../packages/components/src/Utils.web';
const FindJobButton = styled(Grid)({
  "display": "flex",
            "padding": "10px 16px",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "8px",
            "borderRadius": "8px",
            "background": "#C7D9F0",
            "minWidth": "0px !important",
            "color": "#17365D",
            "fontFamily": "Arial",
            "fontSize": "16px",
            "fontStyle": "normal",
            "fontWeight": "bold",
            "lineHeight": "24px",
            cursor: "pointer",
            '&:hover': {
                backgroundColor: '#C7D9F0',
            },
});

  

// Customizable Area End
const CustomGrid = styled(Grid)({
  padding: "10px 0px",
  background: "#FFFFFF",
  margin: "0px",
  width: "100%",
  '@media (max-width: 600px)': {
    padding: "10px",
  },
  maxWidth: "1200px",
});


class Catalogue1 extends CatalogueController1 {

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>

        <Helmet>
          <meta
            name="description"
            content={this.getJobDescription()}
          />
          <link rel="canonical" href={location.toString()} />
          <meta name="robots" content="index, follow" />
          <meta
            name="keywords"
            content={this.getJobTags()}
          />
        </Helmet>

                <CreateJobSuccessModal open={this.state.applyJobModal} closeModal={this.closeModal}/>
        <CustomGrid
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item container spacing={3} >
            <Grid
              item
              container
              sm={5}
            >
              <SearchField value={this.state.searchInputValue.jobTitle} placeHolder="Investment Professor" name='jobTitle' field="What" icon={searchicon} onChangeHandler={this.onChangeHandler}/>
            </Grid>
            <Grid
              item
              container
              sm={5}
            >
              <AutoCompleteSeacrh handleChangeValue={this.handleSearchLocationChangeField} placeHolder="Country, City or Town" field="Where" name="location" icon={locationicon} onChangeHandler={this.onChangeHandler} options={this.state.seachLoactionOption} value={this.state.searchInputValue.location}/>
            </Grid>
            <Grid
              item
              container
              sm={2}
            >

              <FindJobButton id="FindJobButton" onClick={this.findJobsHandlerButton}>
               Find Jobs
              </FindJobButton>
            </Grid>
          </Grid>
          <Grid item container >
            <Grid sm={10} item container
              spacing={1}
            >
              <Grid item>
                <CustomSelect
                  options={configJSON.datePostedOptions}
                  fieldName="Date posted"
                  handleMenuItemClick={this.handleMenuItemClick}
                  value={this.state.postedDate}
                />
              </Grid>
              <Grid item>
                <CustomLocationPopup
                  options={[]}
                  fieldName="Location"
                  countries={this.state.countries}
                  city={this.state.cities}
                  countryListHandler={this.countryListHandler}
                  cityListHandler={this.cityListHandler}
                  locationRadioButtonHandler={this.locationRadioButtonHandler}
                  handleCountryChange={this.handleCountryChange}
                  handleCityChange={this.handleCityChange}
                  state={this.state}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  options={configJSON.postedBy}
                  fieldName="Posted by"
                  handleMenuItemClick={this.postedByHandleClick}
                  value={this.state.postedtBy}
                />
              </Grid>
              <Grid item>
                <CustomeSelectComponent
                  options={this.state.skillsList}
                  fieldName="Skills"
                  handleMenuItemClick={this.skillsByHandleClick}
                  values={this.state.selectedSkills}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  options={this.state.experienceList}
                  fieldName="Experience"
                  handleMenuItemClick={this.experienceHandleClick}
                  value={this.state.selectedExperiences}
                />
              </Grid>
              {isAnyFilterSelected(
                this.state.searchInputValue.jobTitle,
                this.state.searchInputValue.location,
                this.state.selectedCityRadio,
                this.state.selectedCountryRadio,
                this.state.postedtBy,
                this.state.selectedSkills,
                this.state.selectedExperiences,
                this.state.postedDate) && <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography id="clear-all-data" onClick={this.clearAllFilters} style={{ fontFamily: "Arial", fontSize: "14px", fontWeight: "bold", textDecoration: "underline", alignSelf: "center", color: "#17365D", cursor: "pointer" }}>Clear-All&nbsp;&#x2715;</Typography>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
          >
            <TabsComponent
              setSkillToShow={this.setSkillToShow}
              getTitleOfApplyButton={this.getTitleOfApplyButton}
              handleApplyJob={this.handleApplyJob}
              saveDraftJobApplyData={this.saveDraftJobApplyData}
              handleNavigationToMessage={this.handleNavigationToMessage}
              goToSignUpPage={this.goToSignUpPage}
              goToLoginPage={this.goToLoginPage}
              mainState={this.state}
              handleShowMore={this.handleShowMore}
              showMore={this.state.showMore}
              resumeRadioButtonSelected={this.resumeRadioButtonSelected}
              getJobStatusApiHandler={this.getJobDetails}
              jobStatus={this.state.jobStatus}
              jobDetails={this.state.jobDetails}
              jobFeedList={this.state.allJobs}
              openUserDetailsHandler={this.openUserDetailsHandler}
              getJobFilledDetails={this.getJobFilledDetails}
              handleChange={this.handleChange} tabIndex={this.state.tabIndex}
              handleSaveAndUnSaveJob={this.handleSaveAndUnSaveJob}
              applyJobHandler={this.applyJobHandler}
              submitJobDetailHandler={this.submitJobDetailHandler}
              loadMoreFunction={this.loadMoreFunction}
              applyJob={this.state.applyJob}
              handleLikeAndDislikeJob={this.handleLikeAndDislikeJob}
              loader={this.state.loaderJobDetail} progress={this.state.progressJobDetail} remainingTime={this.state.remainingTimeJobDetail}
            />
          </Grid>
        </CustomGrid>
        <ToastContainer/>
        <Modal
                  open={this.state.isModalOpen}
                  onClose={this.handleModalClose}
                  style={{ width: '546px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '33%',  }}
                >
                  <Grid container>
                  <StyledModalStyle>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                          <Box ></Box>
                                          <CloseIcon data-test-id="close-forgot-password-modal" style={{marginTop: '5px'}} onClick={this.handleModalClose} />
                                        </Box>

                                        <StyledTitle>
                                        Subscribe now to apply 
                                        </StyledTitle>
                                        <GreyFont>
                                        Maximize your applying potential. Upgrade your plan to exceed message limits and unlock endless communication possibilities.
                                        </GreyFont>
                                        <Box sx={{ width: '100%' }}>



                 
                                          <StyledButtonContainer>
                                          <StyledBackButton data-test-id="modal-close" onClick={this.handleModalClose} variant="contained" color="primary">Cancel</StyledBackButton>

                                          <StyledDarkButton data-test-id="navigate"  onClick={this.handleShowPlans} type="button" >Subscribe</StyledDarkButton>
                                          </StyledButtonContainer> 
                                        </Box>
                                      </StyledModalStyle>
                  </Grid>
                </Modal>
                <Toaster children={(toast) => <div style={{ 
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: '#fff',
                  height: "42px",
                  padding: '8px 16px',
                  borderRadius: '8px',
                  boxShadow:"0px 6px 15px -3px #00000026",
                  fontFamily: "Arial",
                  fontWeight: "400",
                  color: "#1F497D",
                  fontSize: "16px",
                  }}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11 21.4016C16.7437 21.4016 21.4 16.7453 21.4 11.0016C21.4 5.2578 16.7437 0.601562 11 0.601562C5.25621 0.601562 0.599976 5.2578 0.599976 11.0016C0.599976 16.7453 5.25621 21.4016 11 21.4016ZM15.8192 9.3208C16.3269 8.81312 16.3269 7.99 15.8192 7.48232C15.3115 6.97464 14.4884 6.97464 13.9807 7.48232L9.69998 11.7631L8.01921 10.0823C7.51153 9.57464 6.68842 9.57464 6.18074 10.0823C5.67306 10.59 5.67306 11.4131 6.18074 11.9208L8.78074 14.5208C9.28842 15.0285 10.1115 15.0285 10.6192 14.5208L15.8192 9.3208Z" fill="#34D399"/>
                    </svg>
                    &nbsp;
                    &nbsp;
                    {toast.message}
                  </div>}
                />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default Catalogue1

const StyledModalStyle = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  padding: '25px 35px',
  borderRadius: '8px',
  color: "#1F497D",
  minWidth: '546px',
}));
const StyledTitle = styled(Grid)(({ theme }) => ({
fontFamily: 'Arial',
fontSize: '24px',
fontWeight: 700,
lineHeight: '32px',
color:'rgba(23, 54, 93, 1)'
}));
const GreyFont = styled(Grid)(({ theme }) => ({
fontFamily: 'Arial',
fontSize: '18px',
fontWeight: 400,
color:'rgba(156, 156, 156, 1)',
marginTop:'25px',
marginBottom:'25px'

  }));

  const StyledButtonContainer = styled(Box)(({ theme }) => ({
    fontFamily: 'Arial',
    fontSize: '16px',
    width: "100%",
    paddingTop: "10px",
    display: "flex",
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: '10px',
  }));
  const StyledDarkButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Arial',
    height:'56px',
    fontSize: '16px',
    padding: "10px 16px",
    background: "rgba(31, 73, 125, 1)",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "bold",
    alignSelf: "flex-end",
    textTransform: "none",
    '&:hover': {
      background: "rgba(31, 73, 125, 1)",
    },
  }));
  
  const StyledBackButton = styled(Button)(({ theme }) => ({
    boxShadow:'none',
    height:'56px',
    fontFamily: 'Arial',
    fontSize: '16px',
    padding: "10px 18px",
    background: "rgba(244, 244, 244, 1)",
    borderRadius: "8px",
    color: "rgba(31, 73, 125, 1)",
    fontWeight: "bold",
    alignSelf: "flex-end",
    textTransform: "none",
    '&:hover': {
      background: "rgba(244, 244, 244, 1)",
    },
  }));
// Customizable Area End
