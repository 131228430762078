import * as React from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';;
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import RichTextEditor from './RichTextEditor';
import { Avatar, FormControl, IconButton } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { gallary } from '../assets';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Uploadmedia3 from '../../../uploadmedia3/src/Uploadmedia3.web';
import { RouteComponentProps } from "react-router-dom";
import SelectTopicComponent from '../../../../components/src/SelectTopicComponent';
import { configJSON } from '../LandingPageController';
import { TruncateWithTooltip } from '../../../../../packages/components/src/Utils.web';
import BootstrapTooltip from '../../../../../packages/blocks/catalogue/src/components/BootstrapTooltip';


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  height: "90%",
  maxHeight: "529px",
  radius: "8px",
  width: '80%',
  maxWidth: '600px',
  overflow: "auto",
  outline: "none"
};

const buttonStyles = {
  width: "120px",
  height: "56px",
  borderRadius: "8px",
  padding: "16px",
  backgroundColor: "#1F497D",
  color: "#F8FAFC",
  fontFamily: 'Arial',
  fontSize: '16px',
};

const disabledButtonStyles = {
  ...buttonStyles,
  backgroundColor: "#CCCCCC", // Change this color as needed
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    richTextContent: {
      "scrollbarWidth": 'none'
    }
  }),
)

interface RouteProps extends RouteComponentProps {
  loading?: boolean;
}

type Props = {
  onClose: () => void,
  open: boolean,
  createFeedPostApi: () => void,
  selectTopicsValue: (e: any) => void,
  selectTopics: {
    [category: string]: {
        icon: string,
        topics: string[]
    };
  },
  selectedTopics: string,
  loading: boolean,
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleStateUpdate: () => void,
  images: any[],
  handleDeleteImage: (index: number) => void,
  postContentHandler: (e: any) => void,
  postDescription: string,
  userProfileName: string,
  isPostImageUploaded: boolean,
  onSelectHandler?: () => void,
  loader: boolean;
  progress: number;
  remainingTime: number;
  profileImage?: string
}

export default function CreateFeedPost(Props: Props) {
  const { onClose, open, postDescription, userProfileName,  postContentHandler, handleStateUpdate, handleImageChange, handleDeleteImage, images, loading, createFeedPostApi, selectedTopics, selectTopicsValue, selectTopics, loader, progress, remainingTime, profileImage } = Props
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = React.useState("");

  React.useEffect(() => {
    let token = localStorage.getItem("authToken");
    setIsLoggedIn(token || "")
  }, [])

  const navigation = () => {
    window.location.replace('/EmailAccountLoginBlock');
  }

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={true}>
          <Box sx={style}>
            <Box
              height={"529px"}
              top={"186px"}
              left={"420px"}

              borderRadius={"8px 0px 0px 0px"}
            >
              <Box
                display={"flex"}
                height={"61px"}

                padding={'20px'}
                borderRadius={"0px 0px 1px 0px"}
              >
                <Avatar style={{ width: "44px", height: "44px", borderRadius: "50%", border: '1px solid grey', backgroundColor: "transparent", fontSize: '14px' }} src={profileImage || ""}>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#F4F4F4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 12C17.79 12 16 13.79 16 16C16 18.21 17.79 20 20 20C22.21 20 24 18.21 24 16C24 13.79 22.21 12 20 12ZM22.1 16C22.1 14.84 21.16 13.9 20 13.9C18.84 13.9 17.9 14.84 17.9 16C17.9 17.16 18.84 18.1 20 18.1C21.16 18.1 22.1 17.16 22.1 16ZM26.1 25C26.1 24.36 22.97 22.9 20 22.9C17.03 22.9 13.9 24.36 13.9 25V26.1H26.1V25ZM12 25C12 22.34 17.33 21 20 21C22.67 21 28 22.34 28 25V27C28 27.55 27.55 28 27 28H13C12.45 28 12 27.55 12 27V25Z" fill="#1F497D" />
                  </svg>
                </Avatar> 
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  height={"32px"}
                  width={"493px"}
                  marginTop={'10px'}
                  marginLeft={'10px'}
                >
                  <BootstrapTooltip title={userProfileName}>
                    <Typography style={{ textAlign: "start", fontWeight: 700, fontSize: "24px", color: "#17365D", fontFamily: "Arial" }}>{TruncateWithTooltip(userProfileName, 25)}</Typography>
                  </BootstrapTooltip>
                  <Box onClick={() => onClose()} style={{ cursor: "pointer" }}>
                    <CloseIcon />
                  </Box>
                </Box>
              </Box>
              <Box
                height={"303px"}
                padding={"0px 40px 0px 40px"}
              >
                <Box height={"82px"} >
                  <Typography style={{ marginBottom: "12px", fontFamily: "Arial", color: "#64748B", fontSize: '14px', fontWeight: 700 }} >Topic</Typography>
                 {
                 isLoggedIn && <SelectTopicComponent 
                      placeHolder="Select"
                      title=""
                      disabled={false}
                      value={selectedTopics}
                      selectTopics={selectTopics}
                      onChange={selectTopicsValue}
                  />
                  }
                </Box>
                <Box
                  display={"flex"}
                  overflow={'scroll'}
                  maxHeight={'195px'}
                  marginTop={'20px'}
                  className={classes.richTextContent}
                >
                  <RichTextEditor data-test-id="richText" postDescription={postDescription} postContentHandler={postContentHandler} images={images} handleDeleteImage={handleDeleteImage} />
                </Box>
              </Box>
              {loader ?
                <div style={{ position: 'absolute', left: '0px', right: '0px', bottom: '240px' }}>
                  < Uploadmedia3 navigation="undefined" id="" progress={progress} time={remainingTime} />
                </div> : <></>
              }
              <Typography style={{ padding: "0 40px", textAlign: "end", color: "rgb(100, 116, 139)" }}>{postDescription.length}/{configJSON.postDescriptionMaxLimit}</Typography>
              <Divider
                style={{ borderBottomWidth: "thin", marginTop: '10px' }}
              />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={'20px'}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  style={{ padding: "10px" }}
                  disabled={images.length === 9}
                >
                  <img src={gallary} alt="gallery" />
                  <input
                    disabled={images.length === 9}
                    type="file"
                    hidden
                    name="photo"
                    accept="image/*"
                    id="group_image"
                    onClick={() => handleStateUpdate()}
                    multiple
                    onChange={(event) => handleImageChange(event)}
                  />
                </IconButton>
                <Button
                  style={loading || !selectedTopics || postDescription.trim() === "" ? disabledButtonStyles : buttonStyles}
                  variant="contained"
                  onClick={createFeedPostApi}
                  disabled={loading || !selectedTopics || postDescription.trim() === ""}
                >
                  Post
                  {loading &&
                    <CircularProgress style={{ color: '#F8FAFC', height: '20px', width: '23px', marginLeft: '10px' }} />
                  }
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}