import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { Autocomplete } from "@material-ui/lab";
import AutoCompleteSearch from "../../../components/src/AutoCompleteSearch";
import { styled } from "@material-ui/styles";
import Tooltip from '@material-ui/core/Tooltip';

export interface Root {
  recruiter_job_listing: RecruiterJobListing
  total_page: number
  current_page: number
}

export interface FilterDataNull {
  recruiter_job_listing: {
    data: never[]
  },
  total_page: number,
  current_page: number
}

export interface RecruiterJobListing {
  data: Daum[]
}

export interface Daum {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  job_title: string
  company_name: string
  city: string
  country: string
  disclose: boolean
  status: string
  created_at: string
}
// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController";
import { bookmarkImg, centerImg, imgB, imgChat, imgF, filter, imgViewAll, searchImg, skipImg, text, userImg, locationIcon, scrollDown, starIC, openMenuicon, EndSearchIcon, SearchIcon } from "./assets";
import Filteroptions from "../../filteritems/src/Filteroptions.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class LandingPageJobsSection extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all bloks
      <Box sx={{
        bgcolor: 'white',
        color: '#17365D',
        borderRadius: '20px',
        padding: '15px',
        position: 'relative',
      }}
      style={{ overflowY: 'auto', minHeight: this.state.filterHeight }}

      >
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Typography style={webstyle.jobTitle}>Relevant Job Openings</Typography>
          </Grid>
          <Grid item>
            <Typography style={{...webstyle.viewAllText, cursor: "pointer"}} onClick={() => this.handleNavigationToJobs()} data-test-id="viewAllJobs">View All</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="space-between" style={{ display: "flex", alignItems: "center", marginTop: '14px' }}>
          <Grid item md={11} style={{padding: "0 4px", marginBottom: "4px"}}>
            {/* <RootAutoComplete
              name="find job list"
              title=""
              params={''}
              height= '32px'
              placeHolder="Search"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.searchFilterData(event) }}
            /> */}
            <RootAutoComplete
              name="find job list"
              placeholder="Search"
              variant="outlined" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.searchFilterData(event) }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="icon" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={EndSearchIcon} alt="icon" />
                  </InputAdornment>
                )
              }}

            />
          </Grid>
          <Grid item md={1}>
            <div>
              <img src={filter} style={{ marginLeft: '-8px', cursor: 'pointer' }} onClick={this.handleToggle} />
            </div>
            <Filteroptions
              data-test-id="filterOptions"
              navigation={undefined}
              id={""}
              openFilter={(val:boolean)=>{this.setOpenFilter(val)}}
              filteredData={(val: Root[] | FilterDataNull) => { this.setJobFieldData(val) }}
              country={(val: string) => { this.setCountryvalue(val) }}
              city={(val: string) => { this.setCityValue(val) }}
              date={(val: string) => { this.setDatevalue(val) }}
              postBy={(val: string) => { this.setPostedByValue(val) }}
              skills={(val: string[]) => { this.setSkillValue(val) }}
              experience={(val: string[]) => { this.setExperienceValue(val) }}
              disableButton={(val: boolean) => { this.setButtonState(val) }}
              experienceSelect={(val: string[]) => { this.setExperienceCheckValue(val) }}
              ref={this.childRef} />
          </Grid>
        </Grid>
        <CustomScrollBox style={{ marginTop: '10px' }}>
          {this.state.jobFilterData.map((item: any, index: number) => (
            <Box key={index} sx={{
              border: '2px solid #F4F4F4',
              borderRadius: '0px 16px 16px 16px',
              padding: '0px',
              marginTop: '10px',
              marginBottom: '0px',
              minHeight: '44px',
              maxWidth: '237PX',
              width: '100%',
              boxSizing: 'border-box',
            }}
            style={{ cursor: "pointer"}}
            id="jobBlock"
            onClick={() => this.navigateToJob(item.id)}
            >
              <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                <Grid 
                  item 
                  style={{paddingTop: '4px', paddingBottom: '2px', display: "flex", justifyContent: "space-between" }}
                >
                  <Tooltip title={(item.attributes.company_name.length < 12) ? '' : item.attributes.company_name}>
                    <Typography noWrap style={webstyle.compnayName}>
                      {item.attributes.company_name}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid 
                  item 
                  style={{paddingTop: '2px', paddingBottom: '2px'}}
                >
                  <Typography noWrap style={webstyle.created}>
                    {item.attributes.created_at}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent="space-between" style={{paddingTop: "4px", paddingBottom: "8px"}}>
                <Grid 
                  item 
                  style={{paddingTop: '4px', paddingBottom: '2px'}}
                >
                  <Tooltip title={(item.attributes.job_title.length < 12) ? '' : item.attributes.job_title}>
                    <Typography noWrap style={webstyle.titleName}>{item.attributes.job_title}</Typography>
                  </Tooltip>
                </Grid>
                <Grid 
                  item 
                  style={{paddingTop: '4px', paddingBottom: '2px'}}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={locationIcon} alt="location" style={{ width: '11px', height: '11px' }} />
                    <Tooltip title={(item.attributes.city.length < 14) ? '' : item.attributes.city}>
                      <Typography noWrap style={webstyle.location}>{item.attributes.city}</Typography>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </Box>
          ))}
          {this.state.currentPageJobFilter < this.state.totalPagesJobFilter &&
            <Box style={{ textAlign: 'center', marginTop: '10px', cursor: 'pointer' }} onClick={() => this.ScrollDown()}>
              <img src={scrollDown} alt="scroll-down" />
            </Box>
          }
        </CustomScrollBox>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomScrollBox = styled(Box)({
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '87px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '57px',
    background: 'var(--Base-Grey-5, #D9D9D9)'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#6C96C1', // Color of the scrollbar thumb
    borderRadius: '57px', // Border radius of the scrollbar thumb
    '&:hover': {
      background: '#3489e0', // Color of the scrollbar thumb on hover
    },
  },
});

const RootAutoComplete = styled(TextField)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: "8px",
  position: 'relative',
  background: "#F4F4F4",
  "& .MuiFormHelperText-contained": {
    marginLeft: '0px'
  },
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    border: "0px solid #9C9C9C",
    padding: '2.5px 5px !important'
  },
  "& .MuiSvgIcon-root": {
    display: 'none'
  },
  "& .MuiAutocomplete-input": {
    padding: "5.5px 4px !important"
  },
  "& .custom-dropdown-icon": {
    width: '24px',
    height: '24px',
    backgroundImage: `url(${openMenuicon})`,
    backgroundSize: 'cover',
    cursor: 'pointer',
    right: 0,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'none'
  },
  "& .MuiOutlinedInput-input": {
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "transparent",
    border: "0px solid #9C9C9C",
    background: "#FFF",
    color: "#6D97C1",
    padding: '5px',
    "fontFamily": "Arial",
    "fontSize": "14px",
    "fontStyle": "normal",
    "fontWeight": "lighter",
    "lineHeight": "22px",
    "&:hover": {
      border: "0px solid #6D97C1",
    },
    "&:focus": {
      border: "0px solid #1F497D",
    },
    "&::placeholder": {
      "color": "#64748B",
      "fontFamily": "Arial",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "lighter",
      "lineHeight": "22px"
    },
    paddingLeft: "20px !important"
  },
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important"
  },
  "& .MuiInputAdornment-positionEnd": {
    position: "absolute",
    right: "10px"
  },
  "& .MuiInputAdornment-positionStart": {
    position: "absolute",
    padding: "2px"
  }
})

const webstyle = {
  compnayName: {
    color: 'var(--Primary-color, #1F497D)',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    maxWidth: '70px',
    background: '#F3F4F9',
    borderRadius: '0px 20px 20px 0px',
    padding: '0px 10px 0px 10px'
  },
  starRating: {
    color: 'var(--Dark-text, #17365D)',
    fontFamily: 'Arial',
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  created: {
    color: 'var(--Dark-text, #17365D)',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    marginRight: '10px',
    maxWidth: '70px',
  },
  titleName: {
    color: 'var(--Dark-text, #17365D)',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: '15px',
    maxWidth: '70px'
  },
  location: {
    color: 'var(--Dark-text, #17365D)',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginRight: '10px',
    maxWidth: '70px',
    paddingLeft: '4px'
  },
  jobTitle: {
    color: 'var(--Dark-text, #17365D)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px'
  },
  viewAllText: {
    color: 'var(--Primary-color, #1F497D)',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px'
  }
}
// Customizable Area End